// src/components/Home.js
import React from 'react';
import { Helmet } from 'react-helmet'; // Import Helmet
import "@fontsource/poppins"; // Defaults to weight 400
import Herohome from '../components/HeroHome';
import ServiceHome from '../components/ServiceHome';
import Testimonial from '../components/Testimonial';
import VideoHome from '../components/VideoHome';
import Subform from '../components/Subform';

const Home = () => {



  return (
    <div>
      <Helmet>
        <title>Join AI - Radiology AI Platform As a Service | Medical AI solutions</title>
        <meta name="description" content="Join AI is pioneering the integration of artificial intelligence in the realms of radiology and endoscopy, transforming diagnostic precision and patient care." />
      </Helmet>
      
      <Herohome />

      <ServiceHome />

      <Testimonial />

      <VideoHome />

      <Subform />
    </div>
  );
};

export default Home;
