// components/Footer.js
import React from 'react';
import lightlogo from '../images/logo.svg';
import darklogo from '../images/dark-logo.svg';
import Miraclelogo from '../images/miracle-logo.svg';
import DarkMiraclelogo from '../images/miracle-logo-dark.svg';
import Miracleslogan from '../images/footerslogan.svg';
import DarkMiracleslogan from '../images/footerslogan-dark.svg';
import { useDarkMode } from './DarkModeContext'; // Import the DarkModeContext
import { Link } from 'react-router-dom';

const Footer = () => {
  const { darkMode } = useDarkMode();

  return (
    <footer>
      <div className={`${darkMode ? 'text-matblack' : 'text-matwhite'}`}>
        <div className={`flex flex-wrap justify-between items-center rounded-2xl mx-auto max-w-screen-2xl p-14 ${darkMode ? 'bg-darkheader' : 'bg-lightfooter'}`}>

          <div className="sm:w-1/2 text-left">
            <Link to="/" className="items-center">
              <img
                src={darkMode ? darklogo : lightlogo}
                className="h-12 sm:h-16"
                alt="JOIN AI"
              />
            </Link>
            <p className={`text-matblack pt-4 pb-8 md:pb-16 font-extralight ${darkMode ? 'text-matwhite' : 'text-matblack'}`}>
              Test multiple Radiology and Endoscopy AI models and choose <br />the best one that fits your workflow.
            </p>

            <div className='flex'>
              <p className={`text-sm hidden md:block pr-3 ${darkMode ? 'text-matwhite' : 'text-matblack'}`}>&copy; {new Date().getFullYear()} JOIN AI. All rights reserved.</p>
              <ul className={`inline-flex space-x-2 text-sm ${darkMode ? 'text-matwhite' : 'text-matblack'}`}>
                <Link to={`/privacy-policy`} className='hover:text-matblue'><li>Privacy Policy</li></Link>
                <Link to={`/cookie-policy`} className='hover:text-matblue'><li>Cookie Policy</li></Link>
              </ul>
            </div>
          </div>
          <div className="text-left sm:w-1/3 border-matpink border-l-2 max-sm:border-none px-16 max-sm:px-1">
            <p className={`py-4 ${darkMode ? 'text-matwhite' : 'text-matblack'}`}>A Product of</p>
            <img
              src={darkMode ? DarkMiraclelogo : Miraclelogo}
              className="h-12 sm:h-16"
              alt="JOIN AI"
            />
            <img src={darkMode ? DarkMiracleslogan : Miracleslogan} className="h-6 mt-4 mb-8 sm:h-7" alt="Miracle Slogan" />

            <a href="https://mat.cyou/joinai" target='_blank'
              className={` text-matwhite font-normal rounded-full text-sm px-4 py-2 focus:outline-none bg-matpink`}>
              Visit Our Site
            </a>
            <p className={`text-sm block md:hidden mt-16 ${darkMode ? 'text-matwhite' : 'text-matblack'}`}>&copy; {new Date().getFullYear()} JOIN AI. All rights reserved.</p>
          </div>
        </div>
      </div>
    </footer>
  )
};

export default Footer;