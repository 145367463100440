import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { useDarkMode } from './DarkModeContext';

const Sidebar = () => {
  const [latestBlogs, setLatestBlogs] = useState([]);
  const [tags, setTags] = useState([]);
  const { darkMode } = useDarkMode();

  useEffect(() => {
    // Fetch latest blogs
    axios.get('https://joinai.today/backend/wp-json/wp/v2/posts?_embed&per_page=5')
      .then(response => {
        setLatestBlogs(response.data);
      })
      .catch(error => {
        console.error('Error fetching latest blogs:', error);
      });

    // Fetch all post tags
    axios.get('https://joinai.today/backend/wp-json/wp/v2/tags')
      .then(response => {
        setTags(response.data);
      })
      .catch(error => {
        console.error('Error fetching tags:', error);
      });
  }, []);

  return (
    <aside className="w-full md:w-1/4 px-4 text-left">
      <h2 className={`text-2xl font-bold mb-4 ${darkMode ? 'text-matwhite' : 'text-matblack'}`}>Latest Blogs</h2>
      <ul>
        {latestBlogs.map(blog => (
          <li key={blog.id} className="mb-2">
            <Link to={`/blog/${blog.slug}`} className={`${darkMode ? 'text-matwhite hover:text-matblue' : 'text-matblack hover:text-matblue'}`}>
              {blog.title && blog.title.rendered}
            </Link>
          </li>
        ))}
      </ul>
      <h2 className={`text-2xl font-bold mt-8 mb-4 ${darkMode ? 'text-matwhite' : 'text-matblack'}`}>Tags</h2>
      <ul>
        {tags.map(tag => (
          <li key={tag.id} className="mb-2">
            <Link to={`/tag/${tag.slug}`} className={`${darkMode ? 'text-matwhite hover:text-matblue' : 'text-matblack hover:text-matblue'}`}>
              {tag.name}
            </Link>
          </li>
        ))}
      </ul>
    </aside>
  );
};

export default Sidebar;
