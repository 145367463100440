import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Sidebar from './Sidebar';
import { useDarkMode } from './DarkModeContext';
import { Helmet } from 'react-helmet';

const Blog = () => {
  const [posts, setPosts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [loading, setLoading] = useState(true);
  const { darkMode } = useDarkMode();

  useEffect(() => {
    // Fetch categories
    axios.get(`https://joinai.today/backend/wp-json/wp/v2/categories`)
      .then(response => {
        setCategories(response.data);
      })
      .catch(error => {
        console.error('Error fetching categories:', error);
      });
  }, []);

  useEffect(() => {
    // Fetch blog posts from WordPress
    let apiUrl = 'https://joinai.today/backend/wp-json/wp/v2/posts?_embed';
    if (selectedCategory) {
      apiUrl += `&categories=${selectedCategory}`;
    }
    axios.get(apiUrl)
      .then(response => {
        setPosts(response.data);
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching blog posts:', error);
        setLoading(false);
      });
  }, [selectedCategory]);

  const cleanExcerpt = (excerpt) => {
    const tempElement = document.createElement('div');
    tempElement.innerHTML = excerpt;
    return tempElement.textContent || tempElement.innerText;
  };

  const handleCategoryClick = (categoryId) => {
    setSelectedCategory(categoryId);
  };

  return (
    <div>
      <Helmet>
        <title>Blog - Join AI</title>
        <meta name="description" content="Blog - Join AI" />
      </Helmet>
      <div className={`max-w-screen-xl mx-auto py-8 md:flex mt-28 px-14 md:px-24`}>
        <main className="w-full md:w-3/4 px-4">
          <h1 className={`text-3xl md:text-4xl font-bold mb-8 ${darkMode ? 'text-matwhite' : 'text-matblack'}`}>Blogs</h1>
          <div className="flex mb-10">
            {categories.map(category => (
              <button
                key={category.id}
                className={`mr-4 px-2 text-xs md:text-base py-1 rounded-md ${selectedCategory === category.id ? 'bg-matblue text-white' : ' border-2 border-matblue text-matblue hover:bg-matblue hover:text-matwhite'}`}
                onClick={() => handleCategoryClick(category.id)}
              >
                {category.name}
              </button>
            ))}
          </div>
          <div className="grid md:grid-cols-2 gap-8">
            {posts.map(post => (
              <div key={post.id} className={`mb-8 ${darkMode ? ' bg-darkTestimonial ' : ' bg-lightTestimonial'} rounded-b-lg`}>
                {post._embedded && post._embedded['wp:featuredmedia'] && (
                  <Link to={`/blog/${post.slug}`}>
                    <img
                      src={post._embedded['wp:featuredmedia'][0].source_url}
                      alt={post.title.rendered}
                      className="w-full h-auto object-cover mb-4 rounded-t-lg"
                    />
                  </Link>
                )}
                <div className='p-4'>
                  {/* <div className="flex mb-2">
                {post._embedded && post._embedded['wp:term'] && post._embedded['wp:term'][1] && post._embedded['wp:term'][1].map(tag => (
                    <span key={tag.id} className="flex mr-1 ml-1 px-2 py-2 items-center bg-darkfooter text-matwhite rounded-md text-xs">{tag.name}</span>
                  ))}
                </div> */}
                  <h2 className="text-xl md:text-2xl font-medium mb-2">
                    <Link to={`/blog/${post.slug}`} className={`${darkMode ? 'text-matwhite hover:text-matblue' : 'text-matblack hover:text-matblue'}`}>
                      <span dangerouslySetInnerHTML={{ __html: post.title.rendered }} />
                    </Link>
                  </h2>
                  <p className={`text-sm font-extralight ${darkMode ? 'text-matwhite' : 'text-matblack'}`} dangerouslySetInnerHTML={{ __html: post.excerpt.rendered.replace(/\[.*?\]/g, '') }}></p>
                </div>
              </div>
            ))}

          </div>
          {loading && <div className="loader"></div>}
        </main>
        <Sidebar />
        <style>
          {loading && '.loader { border: 8px solid #f3f3f3; border-top: 8px solid #3498db; border-radius: 50%; width: 50px; height: 50px; animation: spin 1s linear infinite; margin: auto; } @keyframes spin { 0% { transform: rotate(0deg); } 100% { transform: rotate(360deg); } }'}
        </style>
      </div>
    </div>

  );
};

export default Blog;
