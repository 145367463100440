import React, { useState } from 'react';
import { useDarkMode } from './DarkModeContext'; 


const VideoModal = ({ videoId, thumbnail, thumbnailLight }) => {
  const [showVideo, setShowVideo] = useState(false);
  const { darkMode } = useDarkMode();


  const playVideo = () => {
    setShowVideo(true);
  };

  return (
    <div className='flex justify-center text-center'>
      <div className="relative w-[850px]">
      <img
        src={`${darkMode ? thumbnail : thumbnailLight}`}
        alt="Thumbnail"
        className="cursor-pointer"
        onClick={playVideo}
      />
      {showVideo && (
        <div className="absolute top-0 left-0 w-full h-full">
          <iframe
            width="100%"
            height="100%"
            src={`https://www.youtube.com/embed/${videoId}?autoplay=1`}
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            className="absolute top-0 left-0 w-full h-full"
          ></iframe>
        </div>
      )}
    </div>
    </div>
  );
};

export default VideoModal;
