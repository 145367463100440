import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt, faPhone, faEnvelope, faFax, faClock } from '@fortawesome/free-solid-svg-icons';
import { useDarkMode } from './DarkModeContext';

const ContactInformation = () => {
  const { darkMode } = useDarkMode(); 
  return (
    <div className="flex flex-col lg:flex-row mx-auto p-12 mt-12 items-center">
      <div className="w-2/3 max-lg:w-full">
        <iframe
          title="Google Map"
          className="w-full h-96 rounded-md shadow-md"
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15499.491241628817!2d100.6108!3d13.78654!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x30e29d5008343de9%3A0x20ba3ecfd3ab60eb!2sMiracle%20Advance%20Technologies%20Co.%2C%20Ltd.!5e0!3m2!1sen!2sus!4v1706096819232!5m2!1sen!2sus"
          allowFullScreen
          loading="lazy"
        ></iframe>
      </div>

      {/* Contact Information */}
      <div className="w-1/3 pl-10 max-lg:pl-0 rounded-md text-left max-lg:w-full max-lg:mt-5">
        <h3 className={`text-lg mb-4 ${darkMode ? 'text-matwhite' : 'text-matblack'}`}>Contact Us</h3>
        <div className="flex mb-4">
          <FontAwesomeIcon icon={faMapMarkerAlt} className={`mt-1 ${darkMode ? 'text-matwhite' : 'text-matblack'} mr-2`} />
          <p className={`font-extralight ${darkMode ? 'text-matwhite' : 'text-matblack'}`}>Miracle Advance Technologies Co. Ltd.<br />
            2521/34 Ladprao Road, <br />
            Khlongchaokhunsing, Wangthonglang, <br />
            Bangkok 10310, Thailand
          </p>
        </div>

        <div className="flex items-center mb-4">
          <FontAwesomeIcon icon={faPhone} className={`${darkMode ? 'text-matwhite' : 'text-matblack'} mr-2`} />
          <p className={`font-extralight ${darkMode ? 'text-matwhite' : 'text-matblack'}`}>+66 2514-0314-7</p>
        </div>

        <div className="flex items-center mb-4">
          <FontAwesomeIcon icon={faFax} className={`${darkMode ? 'text-matwhite' : 'text-matblack'} mr-2`} />
          <p className={`font-extralight ${darkMode ? 'text-matwhite' : 'text-matblack'}`}>+66 2514-0328</p>
        </div>

        <div className="flex items-center mb-4">
          <FontAwesomeIcon icon={faEnvelope} className={`${darkMode ? 'text-matwhite' : 'text-matblack'} mr-2`} />
          <p className={`font-extralight ${darkMode ? 'text-matwhite' : 'text-matblack'}`}>info@joinai.today</p>
        </div>

        <div className="flex items-center">
          <FontAwesomeIcon icon={faClock} className={`${darkMode ? 'text-matwhite' : 'text-matblack'} mr-2`} />
          <p className={`font-extralight ${darkMode ? 'text-matwhite' : 'text-matblack'}`}>Mon-Fri: 0900 - 1800 hrs</p>
        </div>

      </div>
    </div>
  );
};
export default ContactInformation;
