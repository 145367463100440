import React from "react";
import { useDarkMode } from '../../DarkModeContext';
import Subinspectra from '../../../images/sub-inspectra.png';
import Subinspectra2 from '../../../images/sub-inspectra2.png';
import workflow from '../../../images/workflow.png';
import { Helmet } from "react-helmet";

const Inspectra = () => {

    const { darkMode } = useDarkMode();

    return (
        <div>
            <Helmet>
                <title>Inspectra CXR | AI Solution for Analyzing Chest X-ray Images</title>
                <meta name="description" content="Inspectra CXR" />
            </Helmet>
            <section className="max-w-screen-xl mx-auto px-24 max-lg:px-12 mt-40">
                <div className={`mx-auto items-center`}>
                    <div className={`text-center max-md:w-full`}>
                        <h1 className={`text-2xl md:text-4xl lg:text-6xl font-bold mb-10 ${darkMode ? 'text-matblue' : 'text-matblue'}`}>Inspectra <br /> <span className={`leading-relaxed ${darkMode ? 'text-matwhite' : 'text-matblack'}`} >CXR</span></h1>
                        <p className={` font-extralight ${darkMode ? 'text-matwhite' : 'text-matblack'}`}>Elevating Chest Imaging Diagnostics with The Power of AI-Assisted Detection</p>
                    </div>
                </div>

                <div className={`flex flex-col md:flex-row mx-auto items-center mt-20`}>
                    <div className={`w-1/3 max-md:w-full text-center rounded-2xl m-3 px-3 ${darkMode ? 'bg-darkTestimonial' : 'bg-lightTestimonial'}`}>

                        <h3 className={`text-base font-medium mb-3 mt-8 ${darkMode ? 'text-matwhite' : 'text-matblack'}`}>Cutting Workload by 40%</h3>
                        <p className={` text-sm font-extralight mb-8 ${darkMode ? 'text-matwhite' : 'text-matblack'}`}>Through <b>real-time analysis</b> and <b>severity-based prioritization</b> with AI.</p>
                    </div>
                    <div className={`w-1/3 max-md:w-full text-center rounded-2xl m-3 px-3 ${darkMode ? 'bg-darkTestimonial' : 'bg-lightTestimonial'}`}>

                        <h3 className={`text-base font-medium mb-3 mt-8 ${darkMode ? 'text-matwhite' : 'text-matblack'}`}>Faster Turnaround Time</h3>
                        <p className={` text-sm font-extralight mb-8 ${darkMode ? 'text-matwhite' : 'text-matblack'}`}>Harness the speed of AI in chest imaging for <b>minimized delays</b> and <b>swift results.</b></p>
                    </div>
                    <div className={`w-1/3 max-md:w-full text-center rounded-2xl m-3 px-3 ${darkMode ? 'bg-darkTestimonial' : 'bg-lightTestimonial'}`}>

                        <h3 className={`text-base font-medium mb-3 mt-8 ${darkMode ? 'text-matwhite' : 'text-matblack'}`}>10X Cost Saving</h3>
                        <p className={` text-sm font-extralight mb-8 ${darkMode ? 'text-matwhite' : 'text-matblack'}`}>Integrating AI in chest imaging for hospitals <b>reduces costs</b> and <b>wait times.</b></p>
                    </div>
                </div>

                <div className={`flex flex-col md:flex-row mx-auto items-center mt-20 rounded-2xl  px-10 ${darkMode ? 'bg-darkTestimonial' : 'bg-lightTestimonial'}`}>

                    <div className={`w-1/2 max-md:w-full text-left rounded-2xl`}>

                        <h3 className={`text-4xl font-bold mb-3 ${darkMode ? 'text-matblue' : 'text-matblue'}`}>About Inspectra <span className={`${darkMode ? 'text-matwhite' : 'text-matblack'}`}>CXR</span></h3>
                        <p className={` text-sm font-extralight ${darkMode ? 'text-matwhite' : 'text-matblack'}`}>Since 2017, Perceptra has been transforming medical diagnostics with Inspectra CXR, an AI-driven X-ray analysis platform. It identifies abnormalities, enhances diagnostic accuracy, and reduces doctor’s workloads by over 40%, leading to early disease detection and lower treatment costs, thereby improving healthcare in Southeast Asia.</p>

                    </div>

                    <div className={`w-1/2 max-md:w-full text-right rounded-2xl`}>
                        <div className="text-right flex justify-end pt-8 pb-5 ">
                            <img src={`${darkMode ? Subinspectra : Subinspectra}`} className="w-[400px]" />
                        </div>

                    </div>
                </div>


                <div className="text-left my-20">
                    <h2 className={` text-xl font-medium mb-5 ${darkMode ? 'text-matblue' : 'text-matblue'}`}>What abnormalities does Inspectra <span className={`${darkMode ? 'text-matwhite' : 'text-matblack'}`}>CXR</span> detect in chest X-ray images?</h2>

                    <p className={` text-base font-extralight ${darkMode ? 'text-matwhite' : 'text-matblack'}`}>
                        Inspectra CXR, powered by AI, identifies 8 critical abnormalities in chest X-ray images, including <b>Lung Opacity, Mass, Nodule, Pulmonary Edema, Atelectasis, Cardiomegaly, Pleural Effusion,</b> and offers support for <b>Tuberculosis screening.</b> Inspectra CXR enhances diagnostic accuracy and can be integrated seamlessly into your medical workflows.
                    </p>
                </div>

                <div className={`flex flex-col md:flex-row mx-auto items-center mt-20 rounded-2xl  px-10 ${darkMode ? 'bg-darkTestimonial' : 'bg-lightTestimonial'}`}>

                    <div className={`w-1/2 max-md:w-full text-left rounded-2xl`}>

                        <h3 className={`text-4xl font-bold mb-3 ${darkMode ? 'text-matblue' : 'text-matblue'}`}> Inspectra <span className={`${darkMode ? 'text-matwhite' : 'text-matblack'}`}>CXR</span> generates</h3>
                        <p className={` text-sm font-extralight ${darkMode ? 'text-matwhite' : 'text-matblack'}`}><b>Inspectra CXR</b> offers features like a heat map of findings, suggesting abnormality and confidence scores to aid radiologist’s decisions, and enabling one-click referrals for expert radiologist confirmation. This streamlined workflow ensures that Inspectra fits effortlessly into your daily routine, enhancing efficiency without adding to your workload.</p>

                    </div>

                    <div className={`w-1/2 max-md:w-full text-right rounded-2xl`}>
                        <div className="text-right flex justify-end pt-8 pb-5 ">
                            <img src={`${darkMode ? Subinspectra2 : Subinspectra2}`} className="w-[400px]" />
                        </div>

                    </div>
                </div>

                <div className="text-center my-20">
                    <h2 className={` text-4xl font-bold mb-5 ${darkMode ? 'text-matblue' : 'text-matblue'}`}>Workflow Never Been Easier</h2>

                    <div className="text-center flex justify-center pt-8 pb-5 m-auto ">
                        <div className={`${darkMode ? 'bg-darkTestimonial' : 'bg-lightTestimonial'} p-10 rounded-2xl`}>
                            <img src={`${darkMode ? workflow : workflow}`} className="w-full" />
                        </div>
                    </div>
                </div>

            </section>
        </div>
    );
};

export default Inspectra;