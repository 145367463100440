import React from 'react';
import { Helmet } from 'react-helmet'; // Import Helmet
import ContactForm from '../components/ContactForm';
import { useDarkMode } from '../components/DarkModeContext'; 
import ContactInformation from '../components/ContactInformation';
import contactAnimatLight from '../images/contactAnimatLight.png'
import contactAnimatDark from '../images/contactAnimatDark.png'


const ContactPage = () => {

  const { darkMode } = useDarkMode();



  return (
    <div>
      <Helmet>
        <title>Contact US | Join AI</title>
        <meta name="description" content="We understand the importance of clear communication and providing personalized solutions for your healthcare needs. Our team is here to assist you and answer any questions you may have." />
      </Helmet>

      <section className="container mx-auto p-4 mt-28">
        <div className={`flex flex-col lg:flex-row mx-auto px-28 max-lg:px-12 items-center`}>
          <div className={`w-1/2 text-left max-lg:w-full`}>
            <h1 className={`text-4xl font-medium mb-10 max-md:text-xl ${darkMode ? 'text-matwhite' : 'text-matblack'}`}>Contact <span className="text-matblue">Us</span></h1>

            <p className={`font-extralight max-md:text-sm ${darkMode ? 'text-matwhite' : 'text-matblack'}`}>We understand the importance of clear communication and providing personalized solutions for your healthcare needs. Our team is here to assist you and answer any questions you may have.</p>
            <ContactForm />
          </div>

          <div>
            <img src={`${darkMode ? contactAnimatDark : contactAnimatLight}`}/>
          </div>
          
        </div>
        <ContactInformation />
      </section>
    </div>


  );
};

export default ContactPage;
