import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import axios from 'axios';
import { useDarkMode } from './DarkModeContext';
import { Helmet } from 'react-helmet';
import Sidebar from './Sidebar';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';


const SinglePost = () => {
  const { slug } = useParams();
  const [post, setPost] = useState({});
  const [categories, setCategories] = useState([]);
  const [seoData, setSeoData] = useState({});
  const [relatedPosts, setRelatedPosts] = useState([]); // Define relatedPosts state
  const [loading, setLoading] = useState(true);
  const { darkMode } = useDarkMode();

  useEffect(() => {
    setLoading(true);


    // Fetch blog post
    axios.get(`https://joinai.today/backend/wp-json/wp/v2/posts?slug=${slug}&_embed`)
      .then(response => {
        setPost(response.data[0]);
        document.title = response.data[0].title.rendered || "Blog Post";

        // Fetch related posts only if post.categories is defined
        if (response.data[0]?.categories) {
          // Fetch related posts based on categories of the current post
          axios.get(`https://joinai.today/backend/wp-json/wp/v2/posts?categories=${response.data[0].categories.join(',')}&_embed`)
            .then(response => {
              setRelatedPosts(response.data);
            })
            .catch(error => {
              console.error('Error fetching related posts:', error);
            });
        }

        // Fetch author information
        if (response.data[0]?.author) {
          axios.get(`https://joinai.today/backend/wp-json/wp/v2/users/${response.data[0].author}`)
            .then(response => {
              setPost(prevState => ({
                ...prevState,
                authorInfo: response.data
              }));
            })
            .catch(error => {
              console.error('Error fetching author information:', error);
            });
        }
      })
      .catch(error => {
        console.error('Error fetching blog post:', error);
      });


    // Fetch categories
    axios.get(`https://joinai.today/backend/wp-json/wp/v2/categories`)
      .then(response => {
        setCategories(response.data);
      })
      .catch(error => {
        console.error('Error fetching categories:', error);
      });



    // Fetch RankMath SEO schema
    axios.get(`https://joinai.today/backend/wp-json/rankmath/v1/getHead?url=https://joinai.today/backend/${slug}/`)
      .then(response => {
        const seoDataString = response.data.head;
        const parser = new DOMParser();
        const doc = parser.parseFromString(seoDataString, 'text/html');
        const metadata = {};

        // Extract meta tags
        doc.head.childNodes.forEach(node => {
          if (node.tagName === 'META') {
            const name = node.getAttribute('name');
            const property = node.getAttribute('property');
            const content = node.getAttribute('content');

            if (name) metadata[name] = content;
            if (property) metadata[property] = content;
          }
        });

        setSeoData(metadata);
      })
      .catch(error => {
        console.error('Error fetching RankMath SEO schema:', error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [slug]);

  const getFeaturedImageURL = () => {
    return post._embedded && post._embedded['wp:featuredmedia'] && post._embedded['wp:featuredmedia'][0].source_url;
  };

  const getRFeaturedImageURL = (item) => {
    return item._embedded && item._embedded['wp:featuredmedia'] && item._embedded['wp:featuredmedia'][0].source_url;
  };

  if (loading) {
    return <p>Loading...</p>;
  }
  // const canonicalUrl = `https://joinai.today/backend/${slug}/`;
  const canonicalUrl = `${process.env.REACT_APP_SERVER_URL}/blog/${slug}`;


  const categoryNames = post.categories && post.categories.map(categoryId => {
    const category = categories.find(cat => cat.id === categoryId);
    return category ? category.name : '';
  });


  const categoryColors = ['#2F91CE']; // Add more colors as needed

  const getCategoryColor = index => {
    return categoryColors[index % categoryColors.length];
  };


  // Settings for Slick Carousel
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1
        }
      }
    ]
  };

  return (
    <div>
      <Helmet>
        <title>{seoData.title}</title>
        <meta name="description" content={seoData.description} />
        <meta name="robots" content={seoData.robots} />
        <link rel="canonical" href={canonicalUrl} />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
        <meta property="og:title" content={seoData.title} />
        <meta property="og:description" content={seoData.description} />
        <meta property="og:url" content={`${process.env.REACT_APP_SERVER_URL}/blog/${slug}`} />
        <meta property="og:site_name" content="JOIN AI" />
        <meta property="og:updated_time" content={post.modified} />
        <meta property="og:image" content={getFeaturedImageURL()} />
        <meta property="og:image:width" content="1024" />
        <meta property="og:image:height" content="660" />
        <meta property="og:image:alt" content={seoData.title} />
        <meta property="og:image:type" content="image/png" />
        <meta property="article:published_time" content={post.date} />
        <meta property="article:modified_time" content={post.modified} />

        {/* Twitter Tags */}
        <meta name="twitter:title" content={seoData.title} />
        <meta name="twitter:description" content={seoData.description} />
        <meta name="twitter:image" content={getFeaturedImageURL()} />
      </Helmet>
      <div className={`max-w-screen-xl min-h-screen mx-auto py-8 mt-28 px-14 md:px-24 ${darkMode ? 'text-white' : 'text-gray-800'} text-left md:flex`}>

        <main className="w-full md:w-3/4 px-4">
          {categories.length > 0 && (
            <p className={`text-sm my-4 ${darkMode ? 'text-matwhite' : 'text-matblack'}`}>
              {categories
                .filter(category => post.categories && post.categories.includes(category.id))
                .map((category, index) => {
                  const { name, slug } = category;
                  return (
                    <Link
                      key={index}
                      to={`/category/${slug}`}
                      style={{
                        backgroundColor: getCategoryColor(index),
                        padding: '4px 8px',
                        borderRadius: '4px',
                        marginRight: '4px',
                        display: 'inline-block',
                        textDecoration: 'none',
                        color: 'white'
                      }}
                    >
                      {name}
                    </Link>
                  );
                })}
            </p>
          )}
          <h1 className={`text-2xl md:text-4xl font-normal mb-2 ${darkMode ? 'text-matwhite' : 'text-matblack'}`}
            dangerouslySetInnerHTML={{ __html: post.title && post.title.rendered }}
          />

          {getFeaturedImageURL() && (
            <img
              src={getFeaturedImageURL()}
              alt={post.title && post.title.rendered}
              className="w-full h-auto my-8 rounded-3xl overflow-hidden"
            />
          )}
          {/* {post.date && <p className={`${darkMode ? 'text-matwhite' : 'text-matblack'}`}>Published on: {new Date(post.date).toLocaleDateString()}</p>} */}




          {post.content && <div className={`font-light leading-7 text-lg BlogCss ${darkMode ? 'text-matwhite' : 'text-matblack'}`} dangerouslySetInnerHTML={{ __html: post.content.rendered }} />}

          {post.authorInfo && (
            <div className={`md:flex author-info items-center p-10 rounded-2xl mt-5 mb-16 ${darkMode ? 'bg-darkTestimonial' : 'bg-lightTestimonial'}`}>
              <div className='w-full md:w-1/4'>
                <img src={post.authorInfo.avatar_urls['96']} alt={post.authorInfo.name} className=' rounded-lg' />
              </div>
              <div className='w-full md:w-3/4'>
                <h3 className=' text-xl text-matblue font-medium'>{post.authorInfo.name}</h3>
                <p className=' text-lg text-matblue font-medium py-1'>{post.authorInfo.designation}</p>
                <p className={`font-light ${darkMode ? 'text-matwhite' : 'text-matblack'}`}>
                  {post.authorInfo.description.slice(0, 100)}
                  {post.authorInfo.description.length > 100 ? '...' : ''}
                  {post.authorInfo.description.length > 100 ? (
                    <span>
                      <Link to={`/author/${post.authorInfo.slug}`} className="text-matblue hover:text-lightTestimonial"> See more</Link>
                    </span>
                  ) : null}
                </p>
              </div>
            </div>
          )}


          <div className="max-w-screen-xl mx-auto">
            <h2 className={`text-3xl font-medium mb-10 ${darkMode ? 'text-matwhite' : 'text-matblack'}`}>Related Posts</h2>
            <Slider {...settings}>
              {relatedPosts.map((relatedPost, index) => (
                <div key={index} className="px-2">
                  <Link to={`/blog/${relatedPost.slug}`} className="block mb-4">
                    <img src={getRFeaturedImageURL(relatedPost)} alt={(relatedPost.title && relatedPost.title.rendered) || ''} className="w-full h-auto mb-2 rounded-xl" />
                    <h3 className={`text-lg font-normal my-4 ${darkMode ? 'text-matwhite' : 'text-matblack'}`}>{relatedPost.title && relatedPost.title.rendered}</h3>
                    <p className={`text-sm ${darkMode ? 'text-matwhite' : 'text-matblack'}`} dangerouslySetInnerHTML={{ __html: (relatedPost.excerpt && relatedPost.excerpt.rendered && relatedPost.excerpt.rendered.replace(/\[.*?\]/g, '')) || '' }}></p>
                  </Link>
                </div>
              ))}
            </Slider>
          </div>
        </main>
        <Sidebar />
        <style>
          {loading && '.loader { border: 8px solid #f3f3f3; border-top: 8px solid #3498db; border-radius: 50%; width: 50px; height: 50px; animation: spin 1s linear infinite; margin: auto; } @keyframes spin { 0% { transform: rotate(0deg); } 100% { transform: rotate(360deg); } }'}
        </style>
      </div>






    </div>

  );
};

export default SinglePost;
