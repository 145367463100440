import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import { useDarkMode } from './DarkModeContext';
import trendBG from '../images/trendBG.png';

const LiveCountUp = () => {
  const startDate = dayjs('2024-02-27T00:00:00'); // Start date and time
  const [currentStudies, setCurrentStudies] = useState(265500);
  const { darkMode } = useDarkMode();

  useEffect(() => {
    const startTime = Date.now();
    const targetValue = calculateStudies(startDate) - 20; // Adjust target value

    const duration = 2800;

    const updateCount = () => {
      const now = Date.now();
      const elapsedTime = now - startTime;
      const maxValue = calculateStudies(startDate); // Get updateCount number
      const target = Math.min(targetValue + 100, maxValue); // Ensure counter doesn't exceed updateCount

      if (elapsedTime < duration) {
        const progress = (elapsedTime / duration) * (target - targetValue);
        setCurrentStudies(Math.floor(targetValue + progress));
        requestAnimationFrame(updateCount);
      } else {
        setCurrentStudies(target);
      }
    };

    updateCount();

    const interval = setInterval(() => {
      setCurrentStudies(calculateStudies(startDate));
    }, 60000);

    return () => clearInterval(interval);
  }, []);

  function calculateStudies(startDate) {
    const now = dayjs();
    const minutesElapsed = now.diff(startDate, 'minute');
    return 265500 + minutesElapsed;
  }

  const gradientStyle = {
    backgroundImage: `linear-gradient(to right, #0881CE, #6DC6FF)`,
    WebkitBackgroundClip: 'text',
    color: 'transparent',
  };

  return (
    <div className={`text-5xl md:text-9xl font-semibold bg-contain bg-no-repeat ${darkMode ? 'text-matwhite' : 'text-matblack'}`} style={{ backgroundImage: `url(${trendBG})`, backgroundPosition: `30%` }}>
      {currentStudies.toLocaleString()}
    </div>
  );
};

export default LiveCountUp;
