import React from "react";
import { Helmet } from "react-helmet";
import { useDarkMode } from "../components/DarkModeContext";

const PrivacyPolicy = () => {
    const { darkMode } = useDarkMode();

    return (
        <section>
            <Helmet>
                <title>Privacy Policy - Join AI</title>
                <meta name="description" content="Privacy Policy - Join AI" />
            </Helmet>

            <div className="max-w-screen-xl mx-auto text-left py-8 mt-28 px-14 md:px-24">
                <p className={`${darkMode ? 'text-matwhite' : 'text-matblack'}`}>
                    <h1 className={`text-5xl font-semibold my-4 text-matblue`}>Join AI Privacy Policy</h1>

                    Join AI’s Privacy Policy describes how Join AI collects, uses, and shares your personal data.<br/>
                    Updated May 17, 2023<br/>

                    Join AI (the “Company”, “us”, “we”, “our”) respects and values the protection of personal data that we collect in relation to your use of our mobile application, Join AI (the “Application”). This Join AI’s Privacy Policy (the “Privacy Policy” or “Policy”) describes how we process your personal data with a view to supporting authorized doctors, radiology, or healthcare professionals in making the medical diagnosis for their patients (the “Services”).
                    The Services are provided as part of our obligations under contracts made with hospitals or other healthcare operators (either public or private) that you have a relationship with. We shall collect, use and disclose your personal data only in a manner and to an extent that is required to provide the Services as stated in this Privacy Policy. We shall strictly comply with the applicable rules, regulations, and standards for data protection as prescribed by the Personal Data Protection Act, B.E. 2562 (2019) (the “PDPA”).
                    By agreeing to Join AI’s Terms of Use, you confirm that you have read and understand this Privacy Policy which explains how we process your personal data in connection with your use of the Application.
                    Please note that the information about patients which we make available and accessible to you through your use of the Application is provided and controlled by the hospitals or other healthcare operators (either public or private) that you have a relationship with. We collect, use and disclose such information only as a data processor providing the Services in accordance with contracts made between the Company and the relevant hospitals or healthcare operators.

                    <h2 className="text-3xl font-semibold my-4">Personal Data We Collected</h2>

                    For the purpose of this Policy, we shall collect the following information in relation to your use of the Application:

                    <ul className="mt-4 mx-10 list-disc">
                        <li>
                            Registration data <br />
                            To use the Join AI application, you must register an account and provide certain personal data to verify your identity and credentials as a doctor or healthcare professional, your relationship with our partner hospitals or healthcare operators, and your authorization to access patient data. Such information includes your name, title, email address, street address, medical license number, specialties & subspecialties, and any additional information you provide during registration. We reserve the right to deny your registration and access to our Services if you fail to provide satisfactory proof of your identity.
                        </li>
                        <li>
                            Use of Application Information  <br />
                            We automatically collect/record information about your use of the Application. Such information includes your access to information about particular patients, how and when you access their information, your written reports, updates, and information regarding medical diagnosis including any changes/revisions you make in relation to your patients.
                        </li>
                        <li>
                            Authorization data <br />
                            We may receive other kinds of your personal data from our partner hospitals or healthcare operators whom you have a relationship with, such as name, medical license, medical ID, and career history. This information will be used to verify your authorization to use the Application and to access certain information about patients.
                        </li>
                        <li>
                            Data Security <br />
                            We take reasonable measures to protect the personal information we collect from you from unauthorized access, disclosure, alteration, or destruction. We use industry-standard security measures to safeguard your personal information.
                        </li>
                    </ul>
                    <h2 className="text-3xl font-semibold my-4">Purposes of Processing</h2>

                    The purposes of our collection, use and disclosure of your personal data are explained in the tabulation below. Your personal data will be processed under one or several of the following bases:

                    <ul className="my-4 mx-10 list-[lower-alpha]">
                        <li>
                            Data processing for prevention or suppression of a danger to life, body or health of a person;
                        </li>
                        <li>
                            Data processing for contractual obligations which must be fulfilled by you, as a party to a contract, or for granting your request prior to entering into a contract;
                        </li>
                        <li>
                            Data processing for performance of duty in pursuance of the Company’s public interest or pursuant to the state power vested on the Company;
                        </li>
                        <li>
                            Data processing pursuant to a legitimate interest of the Company or a third party, except that the fundamental right in your personal data takes precedence over the legitimate interest of the Company or that third party; and Data processing for compliance with any laws applicable to the Company.
                        </li>
                    </ul>

                    If we are not allowed to process your personal data under any one of the aforesaid bases, we will seek your explicit consent. In that case, you can withdraw your consent at any time by contacting the Company through the means of communication provided in this Policy.

                    <div className={`my-4 relative overflow-x-auto border rounded-lg ${darkMode ? 'border-darkTestimonial' : 'border-lightTestimonial'}`}>
                        <table className="w-full table-auto text-sm text-left rtl:text-right">
                            <thead className={`w-full text-sm uppercase border-b ${darkMode ? 'text-matwhite bg-darkTestimonial border-darkTestimonial' : 'text-matblack bg-lightTestimonial border-lightTestimonial'}`}>
                                <tr>
                                    <th scope="col" className={`px-6 py-3 rounded-tl-lg border-r ${darkMode ? 'border-matblack' : 'border-matwhite'}`}>Purposes</th>
                                    <th scope="col" className="px-6 py-3 rounded-tr-lg">Legal Bases</th>
                                </tr>
                            </thead>
                            <tbody className="w-full">
                                <tr>
                                    <td className={`w-1/2 border-r border-b px-6 py-4 ${darkMode ? 'border-darkTestimonial' : 'border-lightTestimonial'}`}>
                                        <b>Authorization Verification</b> <br />
                                        We will use your registration data in conjunction with the authorization data we receive from our partner hospitals or healthcare operators in order to verify your identity and capacity as a doctor or a healthcare professional, as well as to verify your authorization to use our Application and access information about patients.
                                    </td>
                                    <td className={`w-1/2 border-b px-6 py-4 ${darkMode ? ' border-darkTestimonial' : ' border-lightTestimonial'}`}>
                                        Your personal data is necessary for the legitimate interest of the Company and of our partner hospitals and healthcare operators.
                                    </td>
                                </tr>
                                <tr>
                                    <td className={`w-1/2 border-r border-b px-6 py-4 ${darkMode ? ' border-darkTestimonial' : ' border-lightTestimonial'}`}>
                                        <b>User Profile</b> <br />
                                        We will associate your registration data with the information about your use of the Application in order to create a user profile. The user profile, containing the necessary information is essential for providing the Services as stipulated in contracts with our partner hospitals and healthcare operators
                                    </td>
                                    <td className={`w-1/2 border-b px-6 py-4 ${darkMode ? ' border-darkTestimonial' : ' border-lightTestimonial'}`}>
                                        Your personal data is necessary for the legitimate interest of the Company and of our partner hospitals and healthcare operators.
                                    </td>
                                </tr>
                                <tr>
                                    <td className={`w-1/2 border-r border-b px-6 py-4 ${darkMode ? ' border-darkTestimonial' : ' border-lightTestimonial'}`}>
                                        <b>Account Management</b> <br />
                                        We will use your information to administer your account, respond to your questions and requests, and send you notifications about the Services.
                                    </td>
                                    <td className={`w-1/2 border-b px-6 py-4 ${darkMode ? ' border-darkTestimonial' : ' border-lightTestimonial'}`}>
                                        Your personal data is necessary for the performance of contracts to which you are a party, particularly the obligations under Join AI’s Terms of Use.
                                        In certain cases, your personal data may be necessary for the legitimate interest of the Company.
                                    </td>
                                </tr>
                                <tr>
                                    <td className={`w-1/2 border-r border-b px-6 py-4 ${darkMode ? ' border-darkTestimonial' : ' border-lightTestimonial'}`}>
                                        <b>Investigation and Evidence</b> <br />
                                        We may use your information to detect and investigate unlawful activities or violations of Join AI’s Terms of Use. Also, we may use your information as ancillary information or ancillary evidence in legal proceedings, which include the disclosure of said information to counsels or third-party counselors.
                                    </td>
                                    <td className={`w-1/2 border-b px-6 py-4 ${darkMode ? ' border-darkTestimonial' : ' border-lightTestimonial'}`}>
                                        Your personal data is necessary for the legitimate interest of the Company and of our partner hospitals and healthcare operators.
                                    </td>
                                </tr>
                                <tr>
                                    <td className={`w-1/2 border-r border-b px-6 py-4 ${darkMode ? ' border-darkTestimonial' : ' border-lightTestimonial'}`}>
                                        <b>Reorganization</b> <br />
                                        During a corporate change in control, a business transfer, or a merger or acquisition, your information may be transferred to the relevant third parties who are involved in such a change.
                                    </td>
                                    <td className={`w-1/2 border-b px-6 py-4 ${darkMode ? ' border-darkTestimonial' : ' border-lightTestimonial'}`}>
                                        Your personal data is necessary for the legitimate interest of the Company and third parties.
                                    </td>
                                </tr>
                                <tr>
                                    <td className={`w-1/2 border-r border-b px-6 py-4 ${darkMode ? ' border-darkTestimonial' : ' border-lightTestimonial'}`}>
                                        <b>Official Request</b> <br />
                                        We may need to secure your personal data for a longer period than the duration specified in this Policy and/or disclose your information according to requests of the government agencies performing their official duties.
                                    </td>
                                    <td className={`w-1/2 border-b px-6 py-4 ${darkMode ? ' border-darkTestimonial' : ' border-lightTestimonial'}`}>
                                        Your personal data is necessary for the Company to comply with the laws applicable to the Company.
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <h2 className="text-3xl font-semibold my-4">Disclosure of Your Personal Data</h2>
                    In general, your personal data will be disclosed to our personnel on a need-to-know basis or to those in charge of the processing. However, your personal data may be disclosed to any of the following third parties:

                    <ul className="mt-4 mx-10 list-disc">
                        <li>
                            Service providers <br />
                            We engage third-party service providers to help us develop, provide, and maintain the Services, including SMS service provider, reporting server, and PACS(Picture archiving and communication system) service providers. We may transfer or provide access to your personal data to these service providers where necessary so that they are able to fulfill their obligations under contracts with us.
                        </li>
                        <li>
                            Partner hospitals and healthcare operators  <br />
                            As the Services are provided as part of our obligations under contracts with partner hospitals and healthcare providers, we may be required to transfer data in relation to your use of the Application to them to fulfill our contractual obligations.
                        </li>
                        <li>
                            Our group companies <br />
                            We may share your personal data in relation to your use of the Application with our subsidiaries and affiliates for the purposes of improving and providing better service.
                        </li>
                        <li>
                            Those involved in the reorganization <br />
                            During a corporate change in control, a business transfer, or a merger or acquisition, your information may be transferred to the relevant third parties who are involved in such a change, including a new party who gains control over the Company or assets of the Company.
                        </li>
                        <li>
                            Persons or agencies with legal authority <br />
                            We may be required to share your personal data according to valid requests of the officials or agencies with legal authority.
                        </li>
                    </ul>

                    <h2 className="text-3xl font-semibold my-4">Storage Security and Duration</h2>
                    We have implemented appropriate technical and organizational measures to protect your personal data from accidental or unlawful destruction, loss, alteration, and unauthorized disclosure of or access to your data.
                    To protect your personal data from unauthorized access, we have implemented a username and password authentication for users to log in to the Application. Please note that you are solely responsible for the confidentiality of your account username and password. Do not give them to other persons or allow any other persons to log in to the Application on your behalf.
                    We normally retain your personal data throughout the term of contracts with our partner hospitals or healthcare operators with which you have a relationship, in accordance with the stipulated government-recommended data retention period.

                    <h2 className="text-3xl font-semibold my-4">International Transfer of Personal Data</h2>
                    For the purposes of developing and maintaining the Application, our service provider who is located in foreign countries may be granted access to your personal data. While they are not considered to have the same level of data protection, we have implemented appropriate safeguards by entering into standard contractual clauses with our service provider so as to ensure an adequate level of protection.

                    <h2 className="text-3xl font-semibold my-4">Your Rights</h2>
                    As a data subject, you have the following rights in regard to your personal data. However, please note that these rights are subject to the provisions of the PDPA, and the contracts we entered into with the relevant partner hospitals and healthcare operators.

                    <ul className="my-4 mx-10 list-disc">
                        <li>
                            Right to withdraw consent <br />
                            Where your personal data are collected, used, or disclosed on the basis of your consent, you can withdraw such consent at any time, unless the withdrawal is limited by law or by the context of a contract existing in your favor. However, such withdrawal does not affect the collection, use, or disclosure of the personal data we have legitimately conducted.
                        </li>
                        <li>
                            Right of access  <br />
                            You have the right of access to your personal data under our control.
                        </li>
                        <li>
                            Right to data portability <br />
                            Where your personal data are collected, used, or disclosed on the basis of your consent or where your personal data are collected without your consent due to contractual obligations which must be fulfilled by you as a party to a contract or for the purpose of granting your request prior to entering into a contract, you have the right to request for such personal data to be made available in an electronic form and transmitted or transferred to other data controllers.
                        </li>
                        <li>
                            Right to objection <br />
                            You have the right to object to your personal data being collected, used, or disclosed.
                        </li>
                        <li>
                            Right to erasure <br />
                            You may request your personal data to be deleted, erased, destroyed, or rendered personally unidentifiable.
                        </li>
                        <li>
                            Right to suspension <br />
                            You have the right to request that the use of your personal data be suspended.
                        </li>
                        <li>
                            Right to rectification <br />
                            You can demand that we rectify your personal data so that they are correct, up-to-date, and not misleading.
                        </li>
                    </ul>

                    However, we reserve the right to proceed as necessary to verify your identification before granting your request, and in some cases, we may deny your request when permitted by law.<br/><br/>

                    If you wish to exercise the aforesaid rights, you can contact us by means of communication given in this Policy.<br/><br/>

                    You may also file complaints with relevant authorities or any governing body if you believe that your personal data have been collected, used, or disclosed in violation of the PDPA.

                    <h2 className="text-3xl font-semibold my-4">Privacy Policy Amendment</h2>
                    This Policy will be reviewed on a regular basis and may be amended from time to time to bring it into line with the practices and laws on data protection. The amended Policy will be posted, and you will be notified of such updates through the Application notification. In case of any material changes to our Policy, we will inform you via email.  By continuing to use the Services after changes are made to this Policy, you agree to such changes.

                    <h2 className="text-3xl font-semibold my-4">Your Rights</h2>
                    If you have any questions about this Privacy Policy or any information collected, used, or disclosed by the Company, or wish to exercise your rights under the PDPA, you can contact us at: info@Joinai.today <br/>

                    <b>Effective date</b>: May 25, 2023

                </p>
            </div>
        </section>
    );
};

export default PrivacyPolicy;