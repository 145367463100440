import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import axios from 'axios';
import { useDarkMode } from './DarkModeContext';
import { Helmet } from 'react-helmet';
import Sidebar from './Sidebar';

const CategoryPage = () => {
  const { slug } = useParams();
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [categoryName, setCategoryName] = useState('');
  const { darkMode } = useDarkMode();

  useEffect(() => {
    // Fetch category ID based on slug
    axios.get(`https://joinai.today/backend/wp-json/wp/v2/categories?slug=${slug}`)
      .then(response => {
        const categoryId = response.data[0]?.id;
        const fetchedCategoryName = response.data[0]?.name;
        if (categoryId) {
          // Set the category name
          setCategoryName(fetchedCategoryName);
          // Fetch posts belonging to the category
          axios.get(`https://joinai.today/backend/wp-json/wp/v2/posts?_embed&categories=${categoryId}`)
            .then(response => {
              setPosts(response.data);
              setLoading(false);
            })
            .catch(error => {
              console.error('Error fetching posts by category:', error);
              setLoading(false);
            });
        } else {
          console.error('Category not found');
          setLoading(false);
        }
      })
      .catch(error => {
        console.error('Error fetching category:', error);
        setLoading(false);
      });
  }, [slug]);

  const cleanExcerpt = (excerpt) => {
    const tempElement = document.createElement('div');
    tempElement.innerHTML = excerpt;
    return tempElement.textContent || tempElement.innerText;
  };

  return (
    <div className={`max-w-screen-xl mx-auto py-8 md:flex mt-28 px-14 md:px-24 ${darkMode ? ' text-white' : ' text-gray-800'}`}>
      <Helmet>
        <title>{categoryName ? `${categoryName} Category` : `${slug} Category`} - Join AI</title>
        <meta name="description" content={`Posts in the category ${slug}`} />
      </Helmet>
      <main className="md:w-3/4 px-4">
        <h1 className={`text-3xl md:text-4xl font-bold mb-8 ${darkMode ? 'text-matwhite' : 'text-matblack'}`}>{categoryName ? categoryName : slug}</h1>
        <div className="grid md:grid-cols-2 gap-8">
          {posts.map(post => (
            <div key={post.id} className={`mb-8 ${darkMode ? ' bg-darkTestimonial ' : 'bg-matwhite'} rounded-b-lg`}>
              {post._embedded && post._embedded['wp:featuredmedia'] && (
                <Link to={`/blog/${post.slug}`}>
                  <img
                    src={post._embedded['wp:featuredmedia'][0].source_url}
                    alt={post.title.rendered}
                    className="w-full h-auto object-cover mb-4 rounded-t-lg"
                  />
                </Link>
              )}
              <div className='p-4'>
                <h2 className="text-xl md:text-2xl font-medium mb-2">
                  <Link to={`/blog/${post.slug}`} className={`${darkMode ? 'text-matwhite hover:text-matblue' : 'text-matblack hover:text-matblue'}`}>
                    {post.title.rendered}
                  </Link>
                </h2>
                <p className={` text-sm font-extralight ${darkMode ? 'text-matwhite' : 'text-matblack'}`}>
                  {cleanExcerpt(post.excerpt && post.excerpt.rendered)}
                </p>
              </div>
            </div>
          ))}
        </div>
        {loading && <div className="loader"></div>}
      </main>
      <Sidebar />

      <style>
        {loading && '.loader { border: 8px solid #f3f3f3; border-top: 8px solid #3498db; border-radius: 50%; width: 50px; height: 50px; animation: spin 1s linear infinite; margin: auto; } @keyframes spin { 0% { transform: rotate(0deg); } 100% { transform: rotate(360deg); } }'}
      </style>
    </div>
  );
};

export default CategoryPage;
