import React, { useEffect } from "react";
import { useDarkMode } from './DarkModeContext'; // Import the DarkModeContext
import bgImgSubformDark from '../images/subformbg-dark.png';
import bgImgSubformLight from '../images/subformbg-light.png';
import subformVectordark from '../images/subformvector-dark.svg';
import LightmBgSub from '../images/LightmBgSub.svg';
import darkmBgSub from '../images/darkmBgSub.svg';

const Subform = () => {
    const { darkMode } = useDarkMode(); // Access the dark mode state from the context

    // Load external script
    useEffect(() => {
        const script = document.createElement("script");
        script.src = "https://www.vbt.io/ext/vbtforms.js?lang=en";
        script.async = true;
        script.charset = "utf-8";
        document.body.appendChild(script);

        return () => {
            // Cleanup: Remove the external script when the component is unmounted
            document.body.removeChild(script);
        };
    }, []);

    useEffect(() => {

        // Script code
        (function (t) {
            var n = "_vbtefso";
            t[n] = t[n] || {};
            t[n][120579] = "eyJkZXBlbmRlbnRGaWVsZHMiOltdLCJlcnJvck1lc3NhZ2VzIjp7ImVycm9ycyI6IlBsZWFzZSBjaGVjayB0aGUgZXJyb3JzIGluIHRoZSBmb3JtLiIsInJlcXVpcmVkIjoiVGhpcyBmaWVsZCBpcyByZXF1aXJlZC4iLCJtaXNzaW5nIjoiUGxlYXNlIHNlbGVjdCBhbiBvcHRpb24uIiwiaW52YWxpZCI6IkludmFsaWQgdmFsdWUgZm9yIHRoaXMgZmllbGQuIiwiaW52YWxpZF9lbWFpbCI6IlBsZWFzZSBlbnRlciBhIHZhbGlkIGVtYWlsIGFkZHJlc3MhIiwiZmlsZV9taXNzaW5nIjoiUGxlYXNlIHNlbGVjdCBmaWxlLiIsImZpbGVfZXh0ZW5zaW9uX2ludmFsaWQiOiJGaWxlIGV4dGVuc2lvbiBub3QgYWxsb3dlZC4iLCJmaWxlX3NpemVfZXhjZWVkZWQiOiJGaWxlIHNpemUgZXhjZWVkZWQgbWF4IHNpemUuIiwiZXJyb3IiOiJFcnJvciBvY2N1cnJlZCEifX0="
        })(window);
    }, []);

    return (
        <section
            className={`items-center justify-center pb-20 max-sm:py-1`}>
            <div className="max-sm:hidden xl:max-w-screen-xl lg:max-w-screen-md max-w-screen-sm mx-auto  text-matwhite xl:p-24 lg:p-12">
                <div className="lg:p-12 rounded-3xl bg-cover bg-no-repeat relative bg-right h-[370px]" style={{ backgroundImage: `url(${darkMode ? bgImgSubformDark : bgImgSubformLight})` }}>
                    <div className="flex flex-col md:flex-row">
                        {/* Left Side */}
                        <div className="lg:w-1/3 text-right justify-end flex">
                            <img src={subformVectordark} alt="Join AI" className=" w-48 p-5 lg:p-0 lg:w-64" />
                        </div>

                        {/* Right Side */}
                        <div className="lg:w-1/2 mt-8 lg:mt-0 ml-12 text-left">
                            <h2 className={`text-xl lg:text-3xl font-bold mb-5 ${darkMode ? 'text-matblue' : 'text-matblue'}`}>Get in touch with our<br />AI specialists!</h2>
                            <p className={`text-sm lg:text-lg mb-4 ${darkMode ? 'text-matblue' : 'text-matblack'}`}>
                                Let us help you choose the best <span className={`${darkMode ? 'text-matwhite' : 'text-matblue'}`}>AI</span> that perfectly <br />fits your existing <span className={`${darkMode ? 'text-matwhite' : 'text-matblue'}`}>Workflow</span>
                            </p>
                        </div>
                        {/* form code */}

                    </div>
                    <div id="vboutEmbedFormWrapper-120579" className="mt-4">
                        <form action="https://www.vbt.io/embedcode/submit/120579/?_format=page" target="_blank" id="vboutEmbedForm-120579" name="vboutEmbedForm-120579" data-vboutform="120579" className="" method="post" encType="multipart/form-data">
                            <div className="vbf-step flex flex-col md:flex-row text-left">
                                <div className="vboutEmbedFormRow md:w-1/2 px-3 mb-6 md:mb-0">
                                    <label className={`text-left lg:text-base text-sm ${darkMode ? 'text-matwhite' : 'text-matblack'}`} htmlFor="custom-374282">Name<span className="required-asterisk text-formError">*</span></label>
                                    <div className="vboutEmbedFormField">
                                        <input className={`appearance-none block w-full vfb-text required border rounded py-3 px-4 my-3 leading-tight focus:outline-none focus:border-matblue ${darkMode ? 'text-matblack bg-matblack' : 'text-matwhite bg-matwhite'}`} name="vbout_EmbedForm[field][374282]" id="custom-374282" type="text" placeholder="Jane" />

                                    </div>
                                </div>
                                <div className="vboutEmbedFormRow md:w-1/2 px-3 mb-6 md:mb-0">
                                    <label className={`text-left lg:text-base text-sm ${darkMode ? 'text-matwhite' : 'text-matblack'}`} htmlFor="custom-374285">Email<span className="required-asterisk text-formError">*</span></label>
                                    <div className="vboutEmbedFormField">
                                        <input className={`appearance-none block w-full vfb-text required border rounded py-3 px-4 my-3 leading-tight focus:outline-none focus:border-matblue ${darkMode ? 'text-matblack bg-matblack' : 'text-matwhite bg-matwhite'}`} name="vbout_EmbedForm[field][374285]" id="custom-374285" type="email" placeholder="Jane@gmail.com" />
                                    </div>
                                </div>
                                <div className="md:w-1/5 px-3">
                                    <div className="vboutEmbedFormRow vfb-submit">
                                        <button type="submit" className="vbf-submit bg-matblue mt-10 py-2 px-5 xl:px-12 rounded-md hover:shadow-[0_20px_20px_-5px_rgb(33,150,243,0.20)]">Submit</button>
                                    </div>
                                </div>
                            </div>
                            <div id="vboutEmbedFormResponse-120579" className="text-left ml-3 font-light text-xs text-formError" style={{ display: 'none' }}></div>
                        </form>
                    </div>
                </div>
            </div>



            <div className="hidden max-sm:block p-20 align-center" >
                <div className="p-2 bg-cover rounded-3xl" style={{ backgroundImage: `url(${darkMode ? darkmBgSub : LightmBgSub})` }} >
                    <div className="lg:w-1/2 mt-8 lg:mt-0 text-left">
                        <h2 className={`text-center text-base font-bold mb-5 ${darkMode ? 'text-matblue' : 'text-matblue'}`}>Get in touch with our<br />AI specialists!</h2>
                        <p className={`text-xs text-center mb-4 ${darkMode ? 'text-matblue' : 'text-matblack'}`}>
                            Let us help you choose the best AI that perfectly fits your existing IT System
                        </p>
                    </div>

                    <div id="vboutEmbedFormWrapper-120579" className="mt-4">
                        <form action="https://www.vbt.io/embedcode/submit/120579/?_format=page" target="_blank" id="vboutEmbedForm-120579" name="vboutEmbedForm-120579" data-vboutform="120579" className="" method="post" encType="multipart/form-data">
                            <div className="vbf-step items-center text-left">
                                <div className="vboutEmbedFormRow px-3 mb-6 md:mb-0">
                                    <label className={`text-left ${darkMode ? 'text-matwhite' : 'text-matblack'}`} htmlFor="custom-374282">Name<span className="required-asterisk">*</span></label>
                                    <div className="vboutEmbedFormField">
                                        <input className={`appearance-none block w-full vfb-text required border rounded py-3 px-4 my-3 leading-tight focus:outline-none focus:border-matblue ${darkMode ? 'text-matblack bg-matblack' : 'text-matwhite bg-matwhite'}`} name="vbout_EmbedForm[field][374282]" id="custom-374282" type="text" placeholder="Jane" />

                                    </div>
                                </div>
                                <div className="vboutEmbedFormRow px-3 mb-2">
                                    <label className={`text-left ${darkMode ? 'text-matwhite' : 'text-matblack'}`} htmlFor="custom-374285">Email<span className="required-asterisk">*</span></label>
                                    <div className="vboutEmbedFormField">
                                        <input className={`appearance-none block w-full vfb-text required border rounded py-3 px-4 my-3 leading-tight focus:outline-none focus:border-matblue ${darkMode ? 'text-matblack bg-matblack' : 'text-matwhite bg-matwhite'}`} name="vbout_EmbedForm[field][374285]" id="custom-374285" type="email" placeholder="Jane@gmail.com" />
                                    </div>
                                </div>
                                <div className="px-3">
                                    <div className="vboutEmbedFormRow vfb-submit text-center">
                                        <button type="submit" className="vbf-submit bg-matblue mt-3 mb-3 py-2 px-8 rounded-md">Submit</button>
                                    </div>
                                </div>
                            </div>
                            <div id="vboutEmbedFormResponse-120579" className="text-left ml-3 text-formError" style={{ display: 'none' }}></div>
                        </form>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Subform;