import React from 'react';
import { Helmet } from 'react-helmet'; // Import Helmet
import serviceVector from '../images/service.png';
import DarkserviceItem1 from '../images/Chest.png';
import DarkserviceItem2 from '../images/Breast.png';
import DarkserviceItem3 from '../images/Stroke.png';
import DarkserviceItem4 from '../images/EndoscopyAI.png';
import LightserviceItem1 from '../images/ChestLight.png';
import LightserviceItem2 from '../images/BreastimLight.png';
import LightserviceItem3 from '../images/StrokeLight.png';
import LightserviceItem4 from '../images/EndoscopyLight.png';
import { useDarkMode } from '../components/DarkModeContext';
import { Link } from 'react-router-dom';


const Services = () => {

    const { darkMode } = useDarkMode(); // Access the dark mode state from the context


    return (
        <div>
            <Helmet>
                <title>Our AI Services | Test Multiple AIs and Choose The Best One</title>
                <meta name="description" content="We Offer Multiple AIAAS Solutions for Healthcare Providers to Ensure Faster Turnaround Times. Test Multiple AIs to Find The Best One That Fits Your Workflow" />
            </Helmet>

            <section className="py-16 bg-no-repeat bg-contain mt-28 mb-10">

                <div className="max-w-screen-xl mx-auto text-left px-12 md:px-20">
                    <h2 className={`text-4xl font-bold mb-5 ${darkMode ? ' text-matblue' : 'text-matblack'}`}>Our <span className={`${darkMode ? 'text-matwhite' : 'text-matblue'}`}>AI Services</span></h2>
                    <p className={`font-light mb-20 ${darkMode ? 'text-matwhite' : 'text-matblack'}`}>
                        AIAAS(Artificial Intelligence as a Service) platform for Healthcare <br />providers. Provide faster turnaround time. Test multiple AIs and choose<br /> the best one that fits your workflow.
                    </p>
                    <div className="flex flex-col md:flex-row item-center">
                        <div className="lg:w-1/4 md:w-1/2 text-left px-3 max-sm:mb-20">
                            <Link to={`/ai-services/chest-imaging-ai`}>
                                <img src={darkMode ? DarkserviceItem1 : LightserviceItem1} alt="Chest Imaging AI" className="w-full h-auto" />
                                <div className="mt-[-160px] px-7">
                                    <h4 className={`text-base xl:text-lg font-medium mb-2 ${darkMode ? 'text-matblue' : 'text-matblue'}`}>Chest Imaging AI</h4>
                                    <p className={`text-sm font-extralight leading-2 xl:leading-5 ${darkMode ? 'text-matwhite' : 'text-matblack'}`}>AI stands beside radiologists and help them find any hidden <b>lung</b> and <b>thoracic</b> conditions for <b>confident</b> and <b>effective</b> treatment.</p>
                                </div>
                            </Link>
                        </div>


                        <div className="lg:w-1/4 md:w-1/2 text-left px-3 max-sm:mb-20">
                            <Link to={`/ai-services/breast-imaging-ai`} >
                                <img src={darkMode ? DarkserviceItem2 : LightserviceItem2} alt="Breast Imaging AI" className="w-full h-auto" />
                                <div className="mt-[-160px] px-7">
                                    <h4 className={`text-base xl:text-lg font-medium mb-2 ${darkMode ? 'text-matblue' : 'text-matblue'}`}>Breast Imaging AI</h4>
                                    <p className={`text-sm font-extralight leading-2 xl:leading-5 ${darkMode ? 'text-matwhite' : 'text-matblack'}`}>Supercharge your  <b>Lumps</b>, <b>Microcalcifications</b>, and <b>Asymmetries</b> detection with AI: Your diagnosis <b>sidekick</b> </p>
                                </div>
                            </Link>
                        </div>

                        <div className="lg:w-1/4 md:w-1/2 text-left px-3 max-sm:mb-20">
                            <Link to={`/ai-services/stroke-ai`}>
                                <img src={darkMode ? DarkserviceItem3 : LightserviceItem3} alt="Stroke AI" className="w-full h-auto" />
                                <div className="mt-[-160px] px-7">
                                    <h4 className={`text-base xl:text-lg font-medium mb-2 ${darkMode ? 'text-matblue' : 'text-matblue'}`}>Stroke AI</h4>
                                    <p className={`text-sm font-extralight leading-2 xl:leading-5 ${darkMode ? 'text-matwhite' : 'text-matblack'}`}>Stroke AI’s integration empowers <b>faster</b>, and <b>accurate</b> diagnoses for patients in the <b>critical</b> minutes that <b>matter</b>.</p>
                                </div>
                            </Link>
                        </div>

                        <div className="lg:w-1/4 md:w-1/2 text-left px-3">
                            <Link to={`/ai-services/endoscopy-ai`}>
                                <img src={darkMode ? DarkserviceItem4 : LightserviceItem4} alt="Endoscopy AI" className="w-full h-auto" />
                                <div className="mt-[-160px] px-7">
                                    <h4 className={`text-base xl:text-lg font-medium mb-2 ${darkMode ? 'text-matblue' : 'text-matblue'}`}>Endoscopy AI</h4>
                                    <p className={`text-sm font-extralight leading-2 xl:leading-5 ${darkMode ? 'text-matwhite' : 'text-matblack'}`}>AI in <b>gastrointestinal</b> endoscopy detects and identifies infections and diseases like <b>Ulcers</b>, <b>Polyps</b>, and <b>Tumors</b>.</p>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
            </section>

        </div>
    );
};

export default Services;
