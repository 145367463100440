import React from "react";
import { useDarkMode } from '../DarkModeContext';
import RightVec from '../../images/ChestService.png';
import RightVecLight from '../../images/lightChest.png';
import product1 from '../../images/productOne.png';
import titleBg from '../../images/titleBg.svg';
import titleBgLight from '../../images/titleBgLight.svg';
import lightLunit from '../../images/lightLunit.png';
import darkLunit from '../../images/darkLunit.png';
import inspectra from '../../images/Inspectra.png';
import siriraj from '../../images/siriraj.png';
import { Link } from "react-router-dom";

const ChestHero = () => {

    const { darkMode } = useDarkMode();

    return (
        <section className="max-w-screen-xl mx-auto px-24 max-lg:px-12 mt-28">
            <div className={`flex flex-col md:flex-row mx-auto items-center`}>
                <div className={`w-1/2 text-left max-md:w-full`}>
                    <h1 className={`text-4xl lg:text-6xl font-bold mb-10 ${darkMode ? 'text-matwhite' : 'text-matblack'}`}>CHEST <br /> <span className="text-matblue">IMAGING AI</span></h1>
                    <p className={` font-extralight ${darkMode ? 'text-matwhite' : 'text-matblack'}`}>Revolutionizing Diagnosis: Precision in <br />Every Image, Clarity in Every Decision</p>
                </div>

                <div className={`w-1/2 text-left max-md:w-full mt-5 md:mt-0`}>
                    <img src={`${darkMode ? RightVec : RightVecLight}`} />
                </div>
            </div>

            <div className="bg-contain md:bg-auto bg-no-repeat relative bg-center p-2 md:p-24 mt-20" style={{ backgroundImage: `url(${darkMode ? titleBg : titleBgLight})` }}>
                <h2 className={`text-4xl font-normal ${darkMode ? 'text-matwhite' : 'text-matblack'}`}>Our  <span className="text-matblue">Chest Imaging</span> AI</h2>
            </div>

            <div className={`flex flex-col md:flex-row mx-auto items-center mt-5 md:mt-10`}>
                <div className={`w-1/2 max-md:w-full text-left rounded-2xl`}>
                    <div className="text-left flex pt-8 pb-5 ">
                        <img src={`${darkMode ? darkLunit : lightLunit}`} className="w-96" />
                    </div>

                </div>
                <div className={`w-1/2 max-md:w-full text-left rounded-2xl`}>

                    <h3 className={`text-4xl font-bold mb-3 ${darkMode ? 'text-matblue' : 'text-matblue'}`}>Lunit INSIGHT CXR</h3>
                    <p className={` text-sm font-extralight ${darkMode ? 'text-matwhite' : 'text-matblack'}`}>Lunit INSIGHT CXR can improve diagnostic performance and patient care outcomes and has been validated in major peer-reviewed journals, demonstrating its ability to detect key thoracic abnormalities including pulmonary nodules, pneumothorax, pneumonia, and active tuberculosis. By flagging these critical findings, Lunit AI reduces the number of overlooked lung abnormalities and other diseases on chest X-ray exams. It also improves reading efficiency by prioritizing images based on abnormality scores. Both radiologists and non-radiology physicians can leverage these AI capabilities to enhance their interpretation of chest X-rays.</p>

                    <Link to={`/ai-services/chest-imaging-ai/lunit-insight-cxr`} className={`text-matblue hover:text-lightBorder`}>Read More</Link>
                </div>
            </div>

            <div className={`flex flex-col-reverse md:flex-row mx-auto items-center mt-20`}>

                <div className={`w-1/2 max-md:w-full text-left rounded-2xl`}>
                    <h3 className={`text-4xl font-bold ${darkMode ? 'text-matblue' : 'text-matblue'}`}>Inspectra CXR</h3>
                    <h4 className={`text-xl font-bold mb-3 ${darkMode ? 'text-matblue' : 'text-matblue'}`}><span className={`${darkMode ? 'text-matwhite' : 'text-matblack'}`}>By</span> Perceptra</h4>
                    <p className={` text-sm font-extralight ${darkMode ? 'text-matwhite' : 'text-matblack'}`}>Inspectra provides AI-powered, real-time diagnostic services, like analyzing chest X-ray images. The platform is designed for seamless integration into your hospital’s existing workflows, offering features like 24/7 reporting, defective image detection, continuous AI learning, and privacy assurance. Inspectra's AI models are developed using Thailand's most extensive X-ray image repository, providing a robust and diverse training dataset​.</p>
                    <Link to={`/ai-services/chest-imaging-ai/inspectra-by-perceptra`} className={`text-matblue hover:text-lightBorder`}>Read More</Link>
                </div>

                <div className={`w-1/2 max-md:w-full text-right rounded-2xl`}>
                    <div className="text-right flex justify-end pt-8 pb-5 ">
                        <img src={`${darkMode ? inspectra : inspectra}`} className="w-[400px]" />
                    </div>

                </div>

            </div>

            <div className={`flex flex-col md:flex-row mx-auto items-center mt-20`}>
                <div className={`w-1/2 max-md:w-full text-left rounded-2xl`}>
                    <div className="text-left flex pt-8 pb-5 ">
                        <img src={`${darkMode ? siriraj : siriraj}`} className="w-[400px]" />
                    </div>

                </div>
                <div className={`w-1/2 max-md:w-full text-left rounded-2xl`}>

                    <h3 className={`text-4xl font-bold mb-3 ${darkMode ? 'text-matblue' : 'text-matblue'}`}>Siriraj AI</h3>
                    <p className={` text-sm font-extralight ${darkMode ? 'text-matwhite' : 'text-matblack'}`}>Siriraj AI is developed by the Siriraj Hospital in Thailand. This AI is used for diagnosing chest radiographs, focusing on lung tuberculosis screening in the Thailand. By analyzing a dataset of hundreds of images of different positive cases, the AI, working alongside human radiologists, is able to identify abnormalities with a precision rate of 96%. Key metrics such as Pairwise Agreement, Intraclass Agreement, and Cohen’s Kappa are used to assess consistency between the AI and radiologists.</p>
                    {/* <a href="/ai-services/chest-imaging-ai/siriraj-ai" className={`text-matblue hover:text-lightBorder`}>Read More</a> */}

                </div>
            </div>

        </section>
    );
};

export default ChestHero;