// ContactForm.js
import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import { useDarkMode } from './DarkModeContext'; // Import the DarkModeContext


const ContactForm = () => {
  const { darkMode } = useDarkMode();

  const [formData, setFormData] = useState({
    fullName: '',
    email: '',
    phone: '',
    message: '',
    chooseOption: '',
  });

  const [successMessage, setSuccessMessage] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);


  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Validate reCAPTCHA
    window.grecaptcha.ready(() => {
      window.grecaptcha.execute('6LeKFdIpAAAAAHClw2SBBMoBtqvxAfoIK0vmt0js', { action: 'submit' })
        .then((token) => {
          // Include the token in the form data
          emailjs.sendForm('service_9gczifq', 'template_bmcj8vf', e.target, 'WIn8AFCPcYvVPIgth', {
              'g-recaptcha-response': token,
            })
            .then((response) => {
              console.log('Thank you for your interest, Our team will get back to you.:', response);
              setSuccessMessage('Thank you for your interest, Our team will get back to you.');
              setErrorMessage(null);
              setFormData({
                fullName: '',
                email: '',
                phone: '',
                message: '',
                chooseOption: '',
              });
            })
            .catch((error) => {
              console.error('Email sending failed:', error);
              setErrorMessage('Error sending message. Please try again later.');
              setSuccessMessage(null);
            });
        });
    });
  };

  

  return (
    <form className="max-w-md mt-8" onSubmit={handleSubmit}>
      <div className="mb-4">
        <label className={`text-left block text-sm font-normal mb-2 ${darkMode ? 'text-matwhite' : 'text-matblack'}`} htmlFor="fullName">
          Full Name
        </label>
        <input
          className={`shadow appearance-none border rounded w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline ${darkMode ? 'text-matwhite bg-matblack' : 'text-matblack bg-matwhite'}`}
          id="fullName"
          type="text"
          name="fullName"
          value={formData.fullName}
          onChange={handleChange}
          required
        />
      </div>
      <div className="mb-4">
        <label className={`text-left block ${darkMode ? 'text-matwhite' : 'text-matblack'} text-sm font-normal mb-2`} htmlFor="email">
          Email
        </label>
        <input
          className={`shadow appearance-none border rounded w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline ${darkMode ? 'text-matwhite bg-matblack' : 'text-matblack bg-matwhite'}`}
          id="email"
          type="email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          required
        />
      </div>
      <div className="mb-4">
        <label className={`text-left block ${darkMode ? 'text-matwhite' : 'text-matblack'} text-sm font-normal mb-2`} htmlFor="phone">
          Phone
        </label>
        <input
          className={`shadow appearance-none border rounded w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline ${darkMode ? 'text-matwhite bg-matblack' : 'text-matblack bg-matwhite'}`}
          id="phone"
          type="tel"
          name="phone"
          value={formData.phone}
          onChange={handleChange}
        />
      </div>
      <div className="mb-4">
        <label className={`text-left block ${darkMode ? 'text-matwhite' : 'text-matblack'} text-sm font-normal mb-2`} htmlFor="chooseOption">
          Interested in
        </label>
        <div className="relative">
          <select
            className={`shadow appearance-none border rounded w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline ${darkMode ? 'text-matwhite bg-matblack' : 'text-matblack bg-matwhite'}`}
            id="chooseOption"
            name="chooseOption"
            value={formData.chooseOption}
            onChange={handleChange}
            required
          >
            <option value="">Choose one...</option>
            <option value="Chest Imaging AI">Chest Imaging AI</option>
            <option value="Breast Imaging AI">Breast Imaging AI</option>
            <option value="Stroke AI">Stroke AI</option>
            <option value="Endoscopy AI">Endoscopy AI</option>
          </select>
          <div className={`pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 ${darkMode ? 'text-matwhite' : 'text-matblue'}`}>
            <svg fill="currentColor" className="h-8 w-8" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M10 12L6 8h8l-4 4z" /></svg>
          </div>
        </div>
      </div>

      <div className="mb-4">
        <label className={`text-left block ${darkMode ? 'text-matwhite' : 'text-matblack'} text-sm font-normal mb-2`} htmlFor="message">
          Message
        </label>
        <textarea
          className={`shadow appearance-none border rounded w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline ${darkMode ? 'text-matwhite bg-matblack' : 'text-matblack bg-matwhite'}`}
          id="message"
          name="message"
          value={formData.message}
          onChange={handleChange}
          rows="4"
          required
        ></textarea>
      </div>
      <div className="flex items-center justify-end">
        <button
          className="bg-matblue hover:bg-blue-700 text-matwhite font-normal py-2 px-4 rounded focus:outline-none focus:shadow-outline hover:shadow-[0_20px_20px_-5px_rgb(33,150,243,0.20)]"
          type="submit"
        >
          Submit
        </button>
      </div>
      {successMessage && (
        <div className="p-2 mb-4 text-matblue text-right">
          {successMessage}
        </div>
      )}
      {errorMessage && (
        <div className="p-2 mb-4 text-formError text-right">
          {errorMessage}
        </div>
      )}


    </form>
  );
};

export default ContactForm;
