import React from "react";
import { useDarkMode } from './DarkModeContext';
import youtubeThumbnail from '../images/YTbgDark.svg';
import youtubeThumbnailLight from '../images/YTbgLight.svg';
import VideoModal from "./VideoModal";


const VideoHome = () => {
    const { darkMode } = useDarkMode();

    return (
        <section className="pt-16 relative">
            <div className="max-w-screen-xl mx-auto p-12 md:p-24 relative text-center">
                <h2 className={`text-4xl font-normal mb-5 ${darkMode ? ' text-matwhite' : 'text-matblack'}`}>Learn More About <span className="text-matblue">Join AI</span></h2>
                <p className={`text-base mb-14 font-extralight ${darkMode ? 'text-matwhite' : 'text-matblack'}`}>Watch the video about Join AI featured in peer-reviewed journals</p>

                <VideoModal
                    videoId="mKw8miklMkw"
                    thumbnail={youtubeThumbnail}
                    thumbnailLight={youtubeThumbnailLight}
                />
            </div>
        </section>
    );
};

export default VideoHome;
