import React, { useState } from 'react';
import { useDarkMode } from "../components/DarkModeContext";
import emailjs from 'emailjs-com';
import { Helmet } from "react-helmet";

const FreeTrial = () => {

    const { darkMode } = useDarkMode();

    const [serviceType, setServiceType] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [organizationName, setOrganizationName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [selectedInterests, setSelectedInterests] = useState([]);
    const [showInterestsDropdown, setShowInterestsDropdown] = useState(false);

    const handleServiceTypeChange = (e) => {
        setServiceType(e.target.value);
    };

    const toggleInterestsDropdown = () => {
        setShowInterestsDropdown(!showInterestsDropdown);
    };

    const handleInterestSelect = (interest) => {
        if (!selectedInterests.includes(interest)) {
            setSelectedInterests([...selectedInterests, interest]);
        }
    };

    const handleInterestRemove = (interest) => {
        const updatedInterests = selectedInterests.filter((item) => item !== interest);
        setSelectedInterests(updatedInterests);
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        // Send email using EmailJS
        emailjs.sendForm('YOUR_SERVICE_ID', 'YOUR_TEMPLATE_ID', e.target, 'YOUR_USER_ID')
            .then((result) => {
                console.log('Email sent successfully:', result.text);
                // Clear form fields after successful submission
                e.target.reset();
                setSelectedInterests([]);
            }, (error) => {
                console.error('Email sending failed:', error);
            });
    };

    return (
        <div className="max-w-md mx-auto mt-32">
            <form className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
                <div className="mb-4">
                    <label className={`text-left block text-sm font-normal mb-2 ${darkMode ? 'text-matwhite' : 'text-matblack'}`} htmlFor="serviceType">
                        Select Service Type
                    </label>
                    <select
                        className={`shadow appearance-none border rounded w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline ${darkMode ? 'text-matwhite bg-matblack' : 'text-matblack bg-matwhite'}`}
                        id="serviceType"
                        value={serviceType}
                        onChange={handleServiceTypeChange}
                    >
                        <option value="">Select Service Type</option>
                        <option value="serviceProvider">Service Provider</option>
                        <option value="healthcareService">Healthcare Service</option>
                    </select>
                </div>
                {serviceType === 'serviceProvider' && (
                    <>
                        <div className="mb-4">
                            <label className={`text-left block text-sm font-normal mb-2 ${darkMode ? 'text-matwhite' : 'text-matblack'}`} htmlFor="firstName">
                                First Name
                            </label>
                            <input
                                className={`shadow appearance-none border rounded w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline ${darkMode ? 'text-matwhite bg-matblack' : 'text-matblack bg-matwhite'}`}
                                id="firstName"
                                type="text"
                                placeholder="First Name"
                                value={firstName}
                                onChange={(e) => setFirstName(e.target.value)}
                            />
                        </div>
                        <div className="mb-4">
                            <label className={`text-left block text-sm font-normal mb-2 ${darkMode ? 'text-matwhite' : 'text-matblack'}`} htmlFor="lastName">
                                Last Name
                            </label>
                            <input
                                className={`shadow appearance-none border rounded w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline ${darkMode ? 'text-matwhite bg-matblack' : 'text-matblack bg-matwhite'}`}
                                id="lastName"
                                type="text"
                                placeholder="Last Name"
                                value={lastName}
                                onChange={(e) => setLastName(e.target.value)}
                            />
                        </div>
                    </>
                )}
                {serviceType === 'healthcareService' && (
                    <>
                        <div className="mb-4">
                            <label className={`text-left block text-sm font-normal mb-2 ${darkMode ? 'text-matwhite' : 'text-matblack'}`} htmlFor="organizationName">
                                Organization Name
                            </label>
                            <input
                                className={`shadow appearance-none border rounded w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline ${darkMode ? 'text-matwhite bg-matblack' : 'text-matblack bg-matwhite'}`}
                                id="organizationName"
                                type="text"
                                placeholder="Organization Name"
                                value={organizationName}
                                onChange={(e) => setOrganizationName(e.target.value)}
                            />
                        </div>
                    </>
                )}
                <div className="mb-4">
                    <label className={`text-left block text-sm font-normal mb-2 ${darkMode ? 'text-matwhite' : 'text-matblack'}`} htmlFor="email">
                        Email
                    </label>
                    <input
                        className={`shadow appearance-none border rounded w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline ${darkMode ? 'text-matwhite bg-matblack' : 'text-matblack bg-matwhite'}`}
                        id="email"
                        type="email"
                        placeholder="Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                </div>
                <div className="mb-4">
                    <label className={`text-left block text-sm font-normal mb-2 ${darkMode ? 'text-matwhite' : 'text-matblack'}`} htmlFor="phone">
                        Phone
                    </label>
                    <input
                        className={`shadow appearance-none border rounded w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline ${darkMode ? 'text-matwhite bg-matblack' : 'text-matblack bg-matwhite'}`}
                        id="phone"
                        type="phone"
                        placeholder="phone"
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                    />
                </div>
                <div className="mb-4">
                    <label className={`text-left block text-sm font-normal mb-2 ${darkMode ? 'text-matwhite' : 'text-matblack'}`} htmlFor="interests">
                        Select Interests
                    </label>
                    <div className="relative">
                        <div className="mb-2 flex flex-wrap">
                            {selectedInterests.map((interest) => (
                                <span key={interest} className="inline-block bg-blue-500 text-white rounded-full px-3 py-1 text-sm font-semibold mr-2 mb-2">
                                    {interest}
                                    <button
                                        type="button"
                                        className="ml-2 outline-none focus:outline-none"
                                        onClick={() => handleInterestRemove(interest)}
                                    >
                                        <svg
                                            className="h-4 w-4 fill-current text-white"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 20 20"
                                        >
                                            <path
                                                fillRule="evenodd"
                                                d="M6.293 6.293a1 1 0 0 1 1.414 0L10 8.586l2.293-2.293a1 1 0 1 1 1.414 1.414L11.414 10l2.293 2.293a1 1 0 1 1-1.414 1.414L10 11.414l-2.293 2.293a1 1 0 1 1-1.414-1.414L8.586 10 6.293 7.707a1 1 0 0 1 0-1.414z"
                                                clipRule="evenodd"
                                            />
                                        </svg>
                                    </button>
                                </span>
                            ))}
                        </div>
                        <div className="mb-2">
                            <div
                                className={`appearance-none border rounded w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline cursor-pointer flex items-center ${darkMode ? 'text-matwhite bg-matblack' : 'text-matblack bg-matwhite'}`}
                                onClick={toggleInterestsDropdown}
                            >
                                {selectedInterests.length === 0 ? 'Select Interests' : 'Selected Interests'}
                                <svg version="1.1" className="fill-matblue ml-5 h-4 w-4" viewBox="0 0 20 20" v>
                                    <path d="M17.418,6.109c0.272-0.268,0.709-0.268,0.979,0s0.271,0.701,0,0.969l-7.908,7.83 c-0.27,0.268-0.707,0.268-0.979,0l-7.908-7.83c-0.27-0.268-0.27-0.701,0-0.969c0.271-0.268,0.709-0.268,0.979,0L10,13.25L17.418,6.109z" />
                                </svg>
                            </div>
                            {showInterestsDropdown && (
                                <div className="absolute z-10 bg-darkTestimonial mt-1 py-1 w-full border rounded shadow-lg">
                                    <div className="overflow-y-auto max-h-32">
                                        <div className="cursor-pointer text-matwhite hover:bg-matblue px-3 py-2" onClick={() => handleInterestSelect('Chest Imaging AI')}>
                                            Chest Imaging AI
                                        </div>
                                        <div className="cursor-pointer text-matwhite hover:bg-matblue px-3 py-2" onClick={() => handleInterestSelect('Breast Imaging AI')}>
                                            Breast Imaging AI
                                        </div>
                                        <div className="cursor-pointer text-matwhite hover:bg-matblue px-3 py-2" onClick={() => handleInterestSelect('Stroke AI')}>
                                            Stroke AI
                                        </div>
                                        <div className="cursor-pointer text-matwhite hover:bg-matblue px-3 py-2" onClick={() => handleInterestSelect('Endoscopy AI')}>
                                            Endoscopy AI
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </form>
        </div>

    );
};

export default FreeTrial;