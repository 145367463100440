import React from "react";
import { Helmet } from 'react-helmet';
import StrokeHero from "../components/services/StrokeHero";
import Subform from "../components/Subform";

const StrokeService = () => {


  return (
    <div>
      <Helmet>
        <title>Stroke Detection AI | Upgrade Your Stroke Response with AI</title>
        <meta name="description" content="Stroke AI’s seamless integration empowers faster, more accurate diagnoses, streamlines workflows, and optimizes care for stroke patients in critical situations." />
      </Helmet>

      <StrokeHero />

      <Subform />

    </div>
  );
};

export default StrokeService;