import React from "react";
import { useDarkMode } from "./DarkModeContext";
import backgroundImageLight from '../images/bg-light.png';
import backgroundImageDark from '../images/bg-dark.png';
import trendBG from '../images/trendBG.png';
import homeJoinAi from '../images/home-join-ai.svg';
import LiveCountUp from "./Counter";
import { Link } from "react-router-dom";

const Herohome = () => {
    const { darkMode } = useDarkMode();

    const backgroundImage = darkMode ? backgroundImageDark : backgroundImageLight;
    return (
        <section
            className={`flex items-center justify-center bg-cover bg-no-repeat relative mt-28`}
            style={{ backgroundImage: `url(${backgroundImage})` }}

        >
            <div className="max-w-screen-xl mx-auto px-14 md:px-24 py-44 max-sm:py-5 mt-10 md:mt-0 text-white ">
                <div className="items-center">
                    {/* Left Side */}
                    <div className="text-center">
                        {/* <h2 className={`text-6xl font-extrabold text-matblue`}>JOIN AI</h2>
                        <h2 className={`text-xl font-normal mb-6 ${darkMode ? 'text-matwhite' : 'text-matblack'}`}>Transform Lives</h2> */}
                        {/* <img src={homeJoinAi} alt="Join AI" className="text-center mx-auto w-64 py-6" /> */}
                        <LiveCountUp />
                        <h1 className={`text-base md:text-4xl font-light my-1 ${darkMode ? 'text-matwhite' : 'text-matblack'}`}>Studies Processed</h1>
                        {/* <h2 className={`text-4xl md:text-8xl font-bold mt-5 md:mt-14 mb-5 md:mb-20 bg-contain bg-no-repeat bg-right-bottom ${darkMode ? 'text-matwhite' : 'text-matblack'}`} >JOIN THE TREND </h2> */}
                        <p className={`animateGradiant text-4xl md:text-8xl font-bold mt-5 md:mt-14 mb-5 md:mb-20 `}>JOIN THE AI TREND</p>

                        <Link to={`/contact`} className={`text-white font-normal rounded-full px-12 py-2 focus:outline-none hover:shadow-[0_20px_20px_-5px_rgb(33,150,243,0.20)]`} style={{ background: 'linear-gradient(45deg, #0680CD, #55A3D5)', color: '#ffffff' }}>
                            Start Free Trial
                        </Link>
                    </div>

                </div>
            </div>
        </section>
    );
};

export default Herohome;