import React from "react";
import { useDarkMode } from '../DarkModeContext';
import RightVec from '../../images/darkEndoscopy.png';
import RightVecLight from '../../images/lightEndoscopy.png';
import product1 from '../../images/CadEye.png';
import titleBg from '../../images/titleBg.svg';
import titleBgLight from '../../images/titleBgLight.svg';

const EndoscopyHero = () => {

    const { darkMode } = useDarkMode();

    return (
        <section className="max-w-screen-xl mx-auto px-24 max-lg:px-12 mt-28">
            <div className={`flex flex-col md:flex-row mx-auto items-center`}>
                <div className={`w-1/2 text-left max-md:w-full`}>
                    <h1 className={`text-4xl lg:text-6xl font-bold mb-10 ${darkMode ? 'text-matwhite' : 'text-matblack'}`}>ENDOSCOPY <span className="text-matblue">AI</span></h1>
                    <p className={` font-extralight ${darkMode ? 'text-matwhite' : 'text-matblack'}`}>Upgrade your endoscopy detection and<br/> diagnostics with artificial intelligence.</p>
                </div>

                <div className={`w-1/2 text-left max-md:w-full mt-5 md:mt-0`}>
                    <img src={`${darkMode ? RightVec : RightVecLight}`} />
                </div>
            </div>

            <div className="bg-contain md:bg-auto bg-no-repeat relative bg-center p-2 md:p-24 mt-20" style={{ backgroundImage: `url(${darkMode ? titleBg : titleBgLight})` }}>
                <h2 className={`text-4xl font-normal ${darkMode ? 'text-matwhite' : 'text-matblack'}`}>Our  <span className="text-matblue">Endoscopy</span> AI</h2>
            </div>

            <div className={`flex flex-col md:flex-row mx-auto items-center mt-5 md:mt-10`}>
                <div className={`w-1/2 max-md:w-full text-left rounded-2xl`}>
                    <div className="text-left flex pt-8 pb-5 ">
                        <img src={product1}  className="w-[450px]"/>
                    </div>

                </div>
                <div className={`w-1/2 max-md:w-full text-left rounded-2xl`}>

                    <h3 className={`text-4xl font-bold ${darkMode ? 'text-matblue' : 'text-matblue'}`}>CAD EYE</h3>
                    <h4 className={`text-xl font-bold mb-3 ${darkMode ? 'text-matblue' : 'text-matblue'}`}><span className={`${darkMode ? 'text-matwhite' : 'text-matblack'}`}>Powered by</span> REiLI</h4>
                    <p className={` text-sm font-extralight ${darkMode ? 'text-matwhite' : 'text-matblack'}`}>
                    CAD EYE is designed to easily integrate with a hospital's existing endoscopy workflow, enhancing the detection and characterization of polyps. CAD EYE also complements endoscopic diagnosis with LCI and BLI technologies. Crafted not to disrupt standard imaging procedures, CAD EYE can be conveniently activated or deactivated in real time. It can seamlessly work with Fujifilm's ELUXEO™ series of endoscopy equipment, ensuring smooth operation and compatibility.
                    </p>
                </div>
            </div>

        </section>
    );
};

export default EndoscopyHero;