import React from "react";
import { Helmet } from "react-helmet";
import { useDarkMode } from "../components/DarkModeContext";

const CookiePolicy = () => {

    const { darkMode } = useDarkMode();


    return (
        <section>
            <Helmet>
                <title>Cookie Policy - Join AI</title>
                <meta name="description" content="Cookie Policy - Join AI" />
            </Helmet>
            <div className="max-w-screen-xl mx-auto text-left py-8 mt-28 px-14 md:px-24">
                <p className={`${darkMode ? 'text-matwhite' : 'text-matblack'}`}>
                    <h1 className={`text-5xl font-semibold my-4 text-matblue`}>Cookie Policy of Join AI</h1>
                    Last Updated: 29/11/2023 <br />

                    Join AI ("we," "us," or "our") uses cookies and similar technologies on our website to enhance the user experience, analyze website usage, and ensure the proper functioning of our services. This Cookie Policy explains how we use cookies and the choices you have regarding their use.

                    <h2 className="text-3xl font-semibold my-4">What Are Cookies?</h2>

                    When you visit a website, cookies are small text files stored on your device (computer, tablet, smartphone). They are widely used to make websites work more efficiently and provide information to the website owners.

                    <h2 className="text-3xl font-semibold my-4">How We Use Cookies</h2>

                    We use cookies for various purposes, including:

                    <ul className=" list-decimal">
                        <li>
                        Essential Cookies: These cookies are necessary for the proper functioning of our website and enable you to navigate our site and use its features.
                        </li>
                        <li>
                        Analytics Cookies: We use analytics cookies to analyze how visitors interact with our website, including the number of visitors, the pages visited, and the time spent on each page. This information helps us improve the performance and content of our site.
                        </li>
                        <li>
                        Functional Cookies: These cookies allow us to remember your preferences, such as language settings, and provide enhanced features when you revisit our website.
                        </li>
                        <li>
                        Third-Party Cookies: Some of our pages may contain content from third-party websites (such as social media buttons) that may set their cookies. We do not have control over these cookies, and you should review the respective third-party privacy policies for more information.
                        </li>
                    </ul>

                    <h2 className="text-3xl font-semibold my-4">Your Cookie Choices</h2>

                    By using our website, you consent to the use of cookies as described in this Cookie Policy. However, you can manage your cookie preferences within our website. Most web browsers allow you to control cookies through their settings. You can find these settings in your browser's 'Options' or 'Preferences' menu. Please note that disabling certain cookies may affect the functionality of our website.

                    <h2 className="text-3xl font-semibold my-4">Changes to This Policy</h2>

                    We may update this Cookie Policy to reflect changes in our practices or for other operational, legal, or regulatory reasons. Any changes will be effective immediately upon posting the revised policy on our website.

                    <h2 className="text-3xl font-semibold my-4">Contact Us</h2>

                    If you have questions about our Cookie Policy, please contact us at [<a href="mailto:info@joinai.today" className="text-matblue">info@joinai.today</a>].

                    Thank you for choosing Join AI We appreciate your trust and are committed to providing you with the best possible online experience.

                </p>
            </div>
        </section>
    );
};

export default CookiePolicy;