import React from "react";
import { useDarkMode } from '../../DarkModeContext';
import lunitVec from '../../../images/lunitVec.png';
import lunitVec2 from '../../../images/lunitVec2.png';
import { Helmet } from "react-helmet";

const Lunit = () => {

    const { darkMode } = useDarkMode();

    return (
        <div>
            <Helmet>
                <title>Lunit Insight CXR | AI Solution for Analyzing Chest X-ray Images</title>
                <meta name="description" content="Lunit Insight CXR The Best AI Solution for Chest X-ray Image Analysis and Detection of Early-Stage Lung Cancer. Upgrade Your Reading Precision with Join AI." />
            </Helmet>

            <section className="max-w-screen-xl mx-auto px-24 max-lg:px-12 mt-40">
                <div className={`mx-auto items-center`}>
                    <div className={`text-center max-md:w-full`}>
                        <h1 className={`text-2xl md:text-4xl lg:text-6xl font-bold mb-10 ${darkMode ? 'text-matblue' : 'text-matblue'}`}>Lunit <br /> <span className={` ${darkMode ? 'text-matwhite' : 'text-matblack'}`}>INSIGHT CXR</span></h1>
                        <p className={` font-extralight ${darkMode ? 'text-matwhite' : 'text-matblack'}`}>Never Miss a Finding in Chest Imaging Analysis</p>
                    </div>
                </div>

                <div className={`flex flex-col md:flex-row mx-auto items-center mt-20`}>
                    <div className={`w-1/4 max-md:w-full text-center rounded-2xl m-3 px-5 h-[190px] ${darkMode ? 'bg-darkTestimonial' : 'bg-lightTestimonial'}`}>

                        <h3 className={`text-base font-medium mb-3 mt-8 ${darkMode ? 'text-matwhite' : 'text-matblack'}`}>Advanced Detection</h3>
                        <p className={` text-sm font-extralight mb-8 ${darkMode ? 'text-matwhite' : 'text-matblack'}`}>Proficiently identifies critical radiologic findings, including nodules, consolidation, and pneumothorax in real-time</p>
                    </div>
                    <div className={`w-1/4 max-md:w-full text-center rounded-2xl m-3 px-5 h-[190px] ${darkMode ? 'bg-darkTestimonial' : 'bg-lightTestimonial'}`}>

                        <h3 className={`text-base font-medium mb-3 mt-8 ${darkMode ? 'text-matwhite' : 'text-matblack'}`}>Improved Turnaround Time</h3>
                        <p className={` text-sm font-extralight mb-8 ${darkMode ? 'text-matwhite' : 'text-matblack'}`}>Using Lunit to prioritize the <b>Radiologist’s workflow</b> resulted in a 13% decrease in overall case reading times</p>
                    </div>
                    <div className={`w-1/4 max-md:w-full text-center rounded-2xl m-3 px-5 h-[190px] ${darkMode ? 'bg-darkTestimonial' : 'bg-lightTestimonial'}`}>

                        <h3 className={`text-base font-medium mb-3 mt-8 ${darkMode ? 'text-matwhite' : 'text-matblack'}`}>Enhanced Reading Efficiency</h3>
                        <p className={` text-sm font-extralight mb-8 ${darkMode ? 'text-matwhite' : 'text-matblack'}`}>Enhanced ability to identify <b>lung cancer</b> at <b>early stages</b> without raising the rate of false positives</p>
                    </div>
                    <div className={`w-1/4 max-md:w-full text-center rounded-2xl m-3 px-5 h-[190px] ${darkMode ? 'bg-darkTestimonial' : 'bg-lightTestimonial'}`}>

                        <h3 className={`text-base font-medium mb-3 mt-8 ${darkMode ? 'text-matwhite' : 'text-matblack'}`}>Integration Support</h3>
                        <p className={` text-sm font-extralight mb-8 ${darkMode ? 'text-matwhite' : 'text-matblack'}`}>Lunit offers integration for both <b>cloud</b> and <b>on-premise</b> systems, designed to fit seamlessly into any <b>hospital existing workflow</b></p>
                    </div>
                </div>


                <div className="text-left my-20">
                    <h2 className={` text-xl font-medium mb-5 ${darkMode ? 'text-matblue' : 'text-matblue'}`}>About Lunit <span className={`${darkMode ? 'text-matwhite' : 'text-matblack'}`}>INSIGHT CXR</span></h2>

                    <p className={` text-base font-extralight ${darkMode ? 'text-matwhite' : 'text-matblack'}`}>
                        Founded in 2013, Lunit is at the forefront of AI-driven diagnostics, conquering cancer through cutting-edge deep-learning technology. With a mission to harness AI for accurate diagnosis and optimal treatment, Lunit's technology is validated by clinical evidence and utilized in thousands of hospitals worldwide​​​​.
                    </p>
                </div>
                <div className="text-left my-20">
                    <h2 className={` text-xl font-medium mb-5 ${darkMode ? 'text-matblue' : 'text-matblue'}`}>What abnormalities does Lunit <span className={`${darkMode ? 'text-matwhite' : 'text-matblack'}`}>INSIGHT CXR</span> <br /> detect in chest X-ray images?</h2>

                    <p className={` text-base font-extralight ${darkMode ? 'text-matwhite' : 'text-matblack'}`}>
                        With an astounding accuracy rate of 97-99%, Lunit INSIGHT CXR meticulously detects 10 abnormal radiologic findings, including Atelectasis, Calcification, Cardiomegaly, Consolidation, Fibrosis, Mediastinal Widening, Nodule, Pleural Effusion, Pneumoperitoneum, and Pneumothorax. Beyond these capabilities, it also offers robust support for Tuberculosis screening, making it an invaluable asset for healthcare providers aiming to enhance diagnostic efficiency and patient care. Embrace the future of medical imaging today with Lunit INSIGHT CXR, where accuracy meets innovation
                    </p>
                </div>
                <div className="text-left my-20">
                    <h2 className={` text-xl font-medium mb-5 ${darkMode ? 'text-matblue' : 'text-matblue'}`}>Lunit  <span className={`${darkMode ? 'text-matwhite' : 'text-matblack'}`}>INSIGHT CXR</span> generates
                    </h2>
                    <div className={`text-left max-md:w-full`}>
                        <img src={lunitVec} />
                    </div>

                </div>
                <div className="text-left my-20">
                    <h2 className={` text-xl font-medium mb-5 ${darkMode ? 'text-matblue' : 'text-matblue'}`}>Workflow <span className={`${darkMode ? 'text-matwhite' : 'text-matblack'}`}> <br />Cloud VS On-Premise</span>
                    </h2>
                    <div className={`text-left max-md:w-full`}>
                        <img src={lunitVec2} />
                    </div>

                </div>
                

            </section>
        </div>
    );
};

export default Lunit;