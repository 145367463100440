import React from "react";
import { Helmet } from 'react-helmet';
import EndoscopyHero from "../components/services/EndoscopyHero";
import Subform from "../components/Subform";

const EndoscopyService = () => {


  return (
    <div>
      <Helmet>
        <title>Endoscopy AI | AI in Gastrointestinal Endoscopy | Joinai.today</title>
        <meta name="description" content="AI in Gastrointestinal Endoscopy Focuses on Detecting and Identifying Infections and Diseases Like Ulcers, Polyps, Tumors, and Helicobacter pylori." />
      </Helmet>

      <EndoscopyHero />

      <Subform />

    </div>
  );
};

export default EndoscopyService;