import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import axios from 'axios';
import { useDarkMode } from './DarkModeContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookSquare, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { Helmet } from 'react-helmet';

const AuthorProfile = () => {
  const { darkMode } = useDarkMode();
  const { authorName } = useParams();
  const [authorInfo, setAuthorInfo] = useState(null);
  const [loading, setLoading] = useState(true);
  const [userBlogs, setUserBlogs] = useState([]);

  useEffect(() => {
    setLoading(true);
    // Fetch author information based on the author name
    axios.get(`https://joinai.today/backend/wp-json/wp/v2/users?search=${authorName}`)
      .then(response => {
        // Assuming only one author is returned
        if (response.data.length > 0) {
          setAuthorInfo(response.data[0]);
          // Fetch user's blogs
          axios.get(`https://joinai.today/backend/wp-json/wp/v2/posts?author=${response.data[0].id}&_embed`)
            .then(blogResponse => {
              setUserBlogs(blogResponse.data);
            })
            .catch(blogError => {
              console.error('Error fetching user blogs:', blogError);
            });
        }
      })
      .catch(error => {
        console.error('Error fetching author information:', error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [authorName]);

  if (loading) {
    return <p>Loading...</p>;
  }

  return (
    <div>
      {authorInfo ? (
        <div className={`max-w-screen-xl mx-auto py-8 mt-28 px-14 md:px-24`}>
          <Helmet>
            <title>{authorInfo.name} - Join AI</title>
            <meta name="description" content={`Explore blogs by ${authorInfo.name}`} />
          </Helmet>
          <div className='md:flex items-center md:gap-6'>
            <div className='w-full md:w-1/12'>
              <img src={authorInfo.avatar_urls['96']} alt={authorInfo.name} className='rounded-lg' />
            </div>
            <div className={`w-full md:w-11/12 text-left ${darkMode ? 'text-matwhite' : 'text-matblack'}`}>
              <h2 className={`text-xl font-semibold`}>{authorInfo.name}</h2>
              <p className='font-light'>{authorInfo.designation}</p>
              {authorInfo.facebook && (
                <a href={authorInfo.facebook} target="_blank" rel="noopener noreferrer">
                  <FontAwesomeIcon icon={faFacebookSquare} className={`text-matblue hover:text-matblue mr-2`} />
                </a>
              )}
              {authorInfo.linkedin && (
                <a href={authorInfo.linkedin} target="_blank" rel="noopener noreferrer">
                  <FontAwesomeIcon icon={faLinkedin} className={`text-matblue hover:text-matblue`} />
                </a>
              )}
            </div>
          </div>
          <p className={`mt-6 text-left p-6 rounded-xl font-light ${darkMode ? 'bg-darkTestimonial text-matwhite' : 'bg-lightTestimonial text-matblack'}`}>{authorInfo.description}</p>
          {/* Render this user blogs  */}
          <div className="grid md:grid-cols-3 gap-6 mt-6">
            {userBlogs.map(blog => (
              <div key={blog.id} className={`mb-8 ${darkMode ? ' bg-darkTestimonial ' : ' bg-lightTestimonial'} rounded-b-lg`}>

                {blog._embedded && blog._embedded['wp:featuredmedia'] && (
                  <Link to={`/blog/${blog.slug}`}>
                    <img src={blog._embedded['wp:featuredmedia'][0].source_url} alt={blog.title.rendered} className="w-full h-auto object-cover mb-4 rounded-t-lg" />
                  </Link>
                )}
                <div className='p-4'>
                  <h2 className="text-xl md:text-2xl font-medium mb-2">
                    <Link to={`/blog/${blog.slug}`} className={`${darkMode ? 'text-matwhite hover:text-matblue' : 'text-matblack hover:text-matblue'}`}>
                      <span dangerouslySetInnerHTML={{ __html: blog.title.rendered }} />
                    </Link>
                  </h2>
                  <p className={`text-sm font-extralight ${darkMode ? 'text-matwhite' : 'text-matblack'}`} dangerouslySetInnerHTML={{ __html: blog.excerpt.rendered.replace(/\[.*?\]/g, '') }} />
                </div>

              </div>
            ))}
          </div>
        </div>
      ) : (
        <p>Author not found</p>
      )}
    </div>
  );
};

export default AuthorProfile;
