import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import axios from 'axios';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useDarkMode } from './DarkModeContext';
import bgImgTestimonial from '../images/bg-testimonial.png';

const Testimonial = () => {
    const { darkMode } = useDarkMode();
    const backgroundImage = bgImgTestimonial;

    const [testimonials, setTestimonials] = useState([]);

    useEffect(() => {
        axios.get('https://joinai.today/backend/wp-json/miracle/v1/testimonials')
            .then(response => {
                setTestimonials(response.data);
            })
            .catch(error => {
                console.error('Error fetching testimonials:', error);
            });
    }, []);

    const settings = {
        dots: false,
        autoplay: true,
        infinite: true,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
        responsive: [{
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }]
    };

    return (
        <section className='items-center justify-center bg-no-repeat relative mt-28' style={{ backgroundImage: `url(${backgroundImage})` }}>
            <div className='px-14 md:px-24 max-w-screen-xl mx-auto'>
                <h2 className={`text-4xl font-normal mb-10 text-left ${darkMode ? 'text-matwhite' : 'text-matblack'}`}>WHAT <span className="text-matblue">EXPERT’S SAY</span></h2>
                <p className={`text-sm md:text-base font-extralight mb-10 text-matblack text-left ${darkMode ? 'text-matwhite' : 'text-matblack'}`}>Let's hear out to the insights from experts in the fields of radiology and endoscopy<br />regarding the impact of AI</p>

                <Slider {...settings}>
                    {testimonials.map(testimonial => (
                        <div key={testimonial.id} className={`p-10 lg:h-92 xl:h-80 max-sm:p-5 rounded-2xl ${darkMode ? ' bg-darkTestimonial' : ' bg-lightTestimonial'}`}>
                            <div className="flex items-center mb-4">
                                <div className="w-16 h-16 rounded-full overflow-hidden">
                                    <img src={testimonial.image_url} alt={testimonial.client_name} className="w-full h-full object-cover" />
                                </div>
                                <div className="ml-4 text-left">
                                    <p className={`font-bold text-xs md:text-base ${darkMode ? 'text-matblue' : 'text-matblue'}`}>{testimonial.client_name}</p>
                                    <p className={`font-extralight text-xs md:text-base ${darkMode ? 'text-matwhite' : 'text-matblack'}`}>{testimonial.designation}</p>
                                    <p className={`font-normal text-xs md:text-base ${darkMode ? 'text-matwhite' : 'text-matblack'}`}>{testimonial.hospital_name}</p>
                                </div>
                            </div>
                            <p className={`text-left font-extralight text-xs md:text-base ${darkMode ? 'text-matwhite' : 'text-matblack'}`}>{testimonial.description}</p>
                        </div>
                    ))}
                </Slider>
            </div>
        </section>
    );
};

export default Testimonial;
