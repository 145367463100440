import React from 'react';
import { useDarkMode } from '../components/DarkModeContext';
import { Helmet } from 'react-helmet';

const NotFound = () => {
    const { darkMode } = useDarkMode();

    return (
        <div>
            <Helmet>
                <title>404 - Page Not Found</title>
                <meta name="description" content="404 - Join AI" />
            </Helmet>

            <div className="min-h-screen flex items-center justify-center">
                <div className={`max-w-lg p-6 shadow-md rounded-md ${darkMode ? 'bg-darkTestimonial' : 'bg-lightTestimonial'}`}>
                    <h2 className={`${darkMode ? 'text-matwhite' : 'text-matblack'} text-3xl font-semibold mb-4`}>404 - Page Not Found</h2>
                    <p className={`${darkMode ? 'text-matwhite' : 'text-matblack'} mb-4`}>Sorry, the page you are looking for does not exist.</p>
                </div>
            </div>
        </div>
    );
};

export default NotFound;
