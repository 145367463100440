import React from "react";
import { useDarkMode } from '../../DarkModeContext';
import RightVec from '../../../images/ChestService.png';
import Icon1 from '../../../images/chestIcon.png';
import serviceMainGrap from '../../../images/serviceMainGrap.svg';

const Siriraj = () => {

    const { darkMode } = useDarkMode();

    return (
        <section className="max-w-screen-xl mx-auto px-24 max-lg:px-12 mt-28">
            <div className={`flex flex-col md:flex-row mx-auto items-center`}>
                <div className={`w-1/2 text-left max-md:w-full`}>
                    <h1 className={`text-2xl md:text-4xl lg:text-6xl font-bold mb-10 ${darkMode ? 'text-matwhite' : 'text-matblack'}`}>Siriraj <br /> <span className="text-matblue">AI</span></h1>
                    <p className={` font-extralight ${darkMode ? 'text-matwhite' : 'text-matblack'}`}>Features that Improve Patient <br />Outcomes and Strev<br />vamline Processes</p>
                </div>

                <div className={`w-1/2 text-left max-md:w-full`}>
                    <img src={RightVec} />
                </div>
            </div>

            <div className={`flex flex-col md:flex-row mx-auto items-center mt-20`}>
                <div className={`w-1/4 max-md:w-full text-center rounded-2xl m-3 px-3 ${darkMode ? 'bg-darkTestimonial' : 'bg-lightTestimonial'}`}>
                    <div className="text-center flex justify-center pt-8 pb-5 ">
                        <img src={Icon1} />
                    </div>
                    <h3 className={`text-base font-medium mb-3 ${darkMode ? 'text-matwhite' : 'text-matblack'}`}>Chest Imaging AI</h3>
                    <p className={` text-sm font-extralight mb-8 ${darkMode ? 'text-matwhite' : 'text-matblack'}`}>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nomet, consectetuer adipiscing elit, sed diam nonummy </p>
                </div>
                <div className={`w-1/4 max-md:w-full text-center rounded-2xl m-3 px-3 ${darkMode ? 'bg-darkTestimonial' : 'bg-lightTestimonial'}`}>
                    <div className="text-center flex justify-center pt-8 pb-5 ">
                        <img src={Icon1} />
                    </div>
                    <h3 className={`text-base font-medium mb-3 ${darkMode ? 'text-matwhite' : 'text-matblack'}`}>Chest Imaging AI</h3>
                    <p className={` text-sm font-extralight mb-8 ${darkMode ? 'text-matwhite' : 'text-matblack'}`}>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nomet, consectetuer adipiscing elit, sed diam nonummy </p>
                </div>
                <div className={`w-1/4 max-md:w-full text-center rounded-2xl m-3 px-3 ${darkMode ? 'bg-darkTestimonial' : 'bg-lightTestimonial'}`}>
                    <div className="text-center flex justify-center pt-8 pb-5 ">
                        <img src={Icon1} />
                    </div>
                    <h3 className={`text-base font-medium mb-3 ${darkMode ? 'text-matwhite' : 'text-matblack'}`}>Chest Imaging AI</h3>
                    <p className={` text-sm font-extralight mb-8 ${darkMode ? 'text-matwhite' : 'text-matblack'}`}>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nomet, consectetuer adipiscing elit, sed diam nonummy </p>
                </div>
                <div className={`w-1/4 max-md:w-full text-center rounded-2xl m-3 px-3 ${darkMode ? 'bg-darkTestimonial' : 'bg-lightTestimonial'}`}>
                    <div className="text-center flex justify-center pt-8 pb-5 ">
                        <img src={Icon1} />
                    </div>
                    <h3 className={`text-base font-medium mb-3 ${darkMode ? 'text-matwhite' : 'text-matblack'}`}>Chest Imaging AI</h3>
                    <p className={` text-sm font-extralight mb-8 ${darkMode ? 'text-matwhite' : 'text-matblack'}`}>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nomet, consectetuer adipiscing elit, sed diam nonummy </p>
                </div>
            </div>

            <div className="flex justify-center my-20">
                <img src={serviceMainGrap} />
            </div>

            <div className="text-left mb-20">
                <h2 className={` text-xl font-medium mb-5 ${darkMode ? 'text-matblue' : 'text-matblue'}`}>Chest Imaging AI</h2>

                <p className={` text-base font-extralight ${darkMode ? 'text-matwhite' : 'text-matblack'}`}>
                    tandards, better data management, and higher security of patient information. Standardized data exchange and storage improve workflow efficiency and decision-making.Seamless integration with HIS, LIS, and RIS for improved patient care, enhanced comwith industry standards, better data management, and higher security of patient information. Standardized data exchange and storage improve workflow efficiency and decision-making.Seamless integration with HIS, LIS, and RIS for ihange and storage improve workflow efficiency and decision-making.

                    tandards, better data management, and higher security of patient information. Standardized data exchange and storage improve workflow efficiency and decision-making.Seamless integration with HIS, LIS, and RIS for improved patient care, enhanced comwith industry standards, better data management, and higher security of patient information. Standardized data exchange and storage improve workflow efficiency and decision-making.Seamless integration with HIS, LIS, and RIS for ihange and storage improve workflow efficiency and decision-making.tandards, better data management, and higher security of patient information. Standardized data exchange and storage improve workflow efficiency and decision-making.Seamless integration with HIS, LIS, and RIS for improved patient care, enhanced comwith industry standards, better data management, and higher security of patient information. Standardized data exchange and storage improve workflow efficiency and decision-making.Seamless integration with HIS, LIS, and RIS for ihange and storage improve workflow efficiency and decision-making.

                    tandards, better data management, and higher security of patient information. Standardized data exchange and storage improve workflow efficiency and decision-making.Seamless integration with HIS, LIS, and RIS for improved patient care, enhanced comwith industry standards, better data management, and higher security of patient information. Standardized data exchange and storage improve workflow efficiency and decision-making.Seamless integration with HIS, LIS, and RIS for ihange and storage improve workflow efficiency and decision-making.
                </p>
            </div>

        </section>
    );
};

export default Siriraj;