import React from "react";
import { Helmet } from 'react-helmet';
import BrestHero from "../components/services/BrestHero";
import Subform from "../components/Subform";

const BreastService = () => {


  return (
    <div>
      <Helmet>
        <title>Breast AI | AI for Breast Screening, Diagnosis and Treatment</title>
        <meta name="description" content="Supercharge Your  Lumps, Microcalcifications & Asymmetries Detection with AI. Unlock The Power of Breast AI for Cancer Detection, Diagnosis & Treatment." />
      </Helmet>

      <BrestHero />

      <Subform />

    </div>
  );
};

export default BreastService;