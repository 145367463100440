import React from "react";
import { useDarkMode } from '../DarkModeContext';
import RightVec from '../../images/BrestService.png';
import RightVecLight from '../../images/lightBreast.png';
import titleBg from '../../images/titleBg.svg';
import titleBgLight from '../../images/titleBgLight.svg';
import lightScreenPoint from '../../images/lightScreenPoint.png';
import darkScreenPoint from '../../images/darkScreenPoint.png';
import synapse from '../../images/synapse.png';
import lightLunit from '../../images/LightLunitIM.png';
import darkLunit from '../../images/darkLunitIM.png';

const BhestHero = () => {

    const { darkMode } = useDarkMode();

    return (
        <section className="max-w-screen-xl mx-auto px-24 max-lg:px-12 mt-28">
            <div className={`flex flex-col md:flex-row mx-auto items-center`}>
                <div className={`w-1/2 text-left max-md:w-full`}>
                    <h1 className={`text-4xl lg:text-6xl font-bold mb-10 ${darkMode ? 'text-matwhite' : 'text-matblack'}`}>BREAST <br /> <span className="text-matblue">IMAGING AI</span></h1>
                    <p className={` font-extralight ${darkMode ? 'text-matwhite' : 'text-matblack'}`}>Earlier detection, clearer vision. Breast <br />Imaging AI brings a new level of <br />precision.</p>
                </div>

                <div className={`w-1/2 text-left max-md:w-full mt-5 md:mt-0`}>
                    <img src={`${darkMode ? RightVec : RightVecLight}`} />
                </div>
            </div>

            <div className="bg-contain md:bg-auto bg-no-repeat relative bg-center p-2 md:p-24 mt-20" style={{ backgroundImage: `url(${darkMode ? titleBg : titleBgLight})` }}>
                <h2 className={`text-4xl font-normal ${darkMode ? 'text-matwhite' : 'text-matblack'}`}>Our <span className="text-matblue">Breast Imaging</span> AI</h2>
            </div>

            <div className={`flex flex-col md:flex-row mx-auto items-center mt-5 md:mt-10`}>
                <div className={`w-1/2 max-md:w-full text-left rounded-2xl`}>
                    <div className="text-left flex pt-8 pb-5 ">
                        <img src={`${darkMode ? darkScreenPoint : lightScreenPoint}`} className="w-[400px]"/>
                    </div>

                </div>
                <div className={`w-1/2 max-md:w-full text-left rounded-2xl`}>
                    <h3 className={`text-4xl font-bold ${darkMode ? 'text-matblue' : 'text-matblue'}`}>ScreenPoint</h3>
                    <h4 className={`text-xl font-bold mb-3 ${darkMode ? 'text-matblue' : 'text-matblue'}`}><span className={`${darkMode ? 'text-matwhite' : 'text-matblack'}`}>By</span> Fujifilm</h4>
                    <p className={` text-sm font-extralight ${darkMode ? 'text-matwhite' : 'text-matblack'}`}>With ScreenPoint Radiologist harness the power of artificial intelligence to improve the accuracy and efficiency of Masses or Lumps, Microcalcifications, Asymmetry detection. With deep learning Artificial Intelligence, ScreenPoint analyze mammograms, assisting radiologists  in identifying potential abnormalities with unparalleled precision. ScreenPoint Breast Imaging AI not only accelerates the diagnostic process but also significantly improves the early detection rates of breast cancer, ensuring timely intervention and treatment.</p>
                </div>
            </div>

            <div className={`flex flex-col-reverse md:flex-row mx-auto items-center mt-20`}>

                <div className={`w-1/2 max-md:w-full text-left rounded-2xl`}>
                    <h3 className={`text-4xl font-bold ${darkMode ? 'text-matblue' : 'text-matblue'}`}>Synapse</h3>
                    <h4 className={`text-xl font-bold mb-3 ${darkMode ? 'text-matblue' : 'text-matblue'}`}><span className={`${darkMode ? 'text-matwhite' : 'text-matblack'}`}>By</span> Fujifilm</h4>
                    <p className={` text-sm font-extralight ${darkMode ? 'text-matwhite' : 'text-matblack'}`}>Synapse AI introduces an innovative approach to breast abnormalities detection and diagnosis with its Image Recognition technology. It can accurately recognize, identify and extract organ regions, regardless of shape deviations and image conditions. Synapse AI helps radiologist analyze imaging data with remarkable precision to identify concerns early, while minimizing false positives and reducing unnecessary biopsies.</p>
                </div>

                <div className={`w-1/2 max-md:w-full text-right rounded-2xl`}>
                    <div className="text-right flex justify-end pt-8 pb-5 ">
                    <img src={`${darkMode ? synapse : synapse}`} className="w-[400px]"/>
                    </div>

                </div>

            </div>

            <div className={`flex flex-col md:flex-row mx-auto items-center mt-20`}>
                <div className={`w-1/2 max-md:w-full text-left rounded-2xl`}>
                    <div className="text-left flex pt-8 pb-5 ">
                        <img src={`${darkMode ? darkLunit : lightLunit}`} className="w-[450px]" />
                    </div>

                </div>
                <div className={`w-1/2 max-md:w-full text-left rounded-2xl`}>

                    <h3 className={`text-4xl font-bold mb-3 ${darkMode ? 'text-matblue' : 'text-matblue'}`}>Lunit Insight MMG</h3>
                    <p className={` text-sm font-extralight ${darkMode ? 'text-matwhite' : 'text-matblack'}`}>Lunit INSIGHT MMG reliably identifies lesions, such as Normal/Benign Nodules and Malignant Nodules indicative of breast cancer, in mammograms with an accuracy of up to 96%. With Lunit INSIGHT MMG, Enhanced Diagnostic Precision, Particularly in Dense Breasts As reported in THE LANCET Digital Health study, radiologists can enhance their diagnostic precision for dense breasts by up to 12% and for fatty breasts by up to 5%. The accuracy of diagnosing cancer in dense breasts improved by 12%, and in fatty breasts by 5%, through AI integration.</p>
                </div>
            </div>

        </section>
    );
};

export default BhestHero;