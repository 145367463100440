import React from "react";
import { useDarkMode } from '../DarkModeContext';
import RightVec from '../../images/darkStroke.png';
import RightVecLight from '../../images/lightStroke.png';
import product1 from '../../images/Reili.png';
import titleBg from '../../images/titleBg.svg';
import titleBgLight from '../../images/titleBgLight.svg';

const StrokeHero = () => {

    const { darkMode } = useDarkMode();

    return (
        <section className="max-w-screen-xl mx-auto px-24 max-lg:px-12 mt-28">
            <div className={`flex flex-col md:flex-row mx-auto items-center`}>
                <div className={`w-1/2 text-left max-md:w-full`}>
                    <h1 className={`text-4xl lg:text-6xl font-bold mb-10 ${darkMode ? 'text-matwhite' : 'text-matblack'}`}>STROKE <span className="text-matblue">AI</span></h1>
                    <p className={` font-extralight ${darkMode ? 'text-matwhite' : 'text-matblack'}`}>Upgrade your stroke response and <br />make better decisions in the critical <br />minutes that matter.</p>
                </div>

                <div className={`w-1/2 text-left max-md:w-full mt-5 md:mt-0`}>
                    <img src={`${darkMode ? RightVec : RightVecLight}`} />
                </div>
            </div>

            <div className="bg-contain md:bg-auto bg-no-repeat relative bg-center p-2 md:p-24 mt-20" style={{ backgroundImage: `url(${darkMode ? titleBg : titleBgLight})` }}>
                <h2 className={`text-4xl font-normal ${darkMode ? 'text-matwhite' : 'text-matblack'}`}>Our  <span className="text-matblue">Stroke</span> AI</h2>
            </div>

            <div className={`flex flex-col md:flex-row mx-auto items-center mt-5 md:mt-10`}>
                <div className={`w-1/2 max-md:w-full text-left rounded-2xl`}>
                    <div className="text-left flex pt-8 pb-5 ">
                        <img src={`${darkMode ? product1 : product1}`} />
                    </div>

                </div>
                <div className={`w-1/2 max-md:w-full text-left rounded-2xl`}>

                   
                    <h3 className={`text-4xl font-bold ${darkMode ? 'text-matblue' : 'text-matblue'}`}>REiLI</h3>
                    <h4 className={`text-xl font-bold mb-3 ${darkMode ? 'text-matblue' : 'text-matblue'}`}><span className={`${darkMode ? 'text-matwhite' : 'text-matblack'}`}>By</span> Fujifilm</h4>
                    <p className={` text-sm font-extralight ${darkMode ? 'text-matwhite' : 'text-matblack'}`}>
                    REiLI is designed to seamlessly integrate into the workflows of healthcare service provider’s current IT systems. REiLI assists radiologists in detecting hemorrhage and large vessel occlusion, enhancing image quality and segmenting anatomy, and helping radiologists make swift and precise decisions in stroke cases.
                    </p>
                </div>
            </div>

        </section>
    );
};

export default StrokeHero;